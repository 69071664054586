import { toJSON } from 'api/APIFunctions';//fromJSON
import { exportCartItems } from 'context/AppContext/CartContext'; //useCartContext
import { getAddress, createEndDate, displaySearchCriteriaDuration, displayDateAndDuration  } from 'context/AppContext/SearchContext'; //

import { UserRequestRoute } from './RequestServiceRoutes';


export const createRequest = async(searchCriteria, cartItems, user, requestDetails) => {
    const end = new Date(searchCriteria?.date)
    end.setMinutes(end.getMinutes()+ searchCriteria?.duration)

    const item = {
        'name': user?.name || '',
        'email': user?.email || '',
        'phone': user?.phone || '',
        'start': searchCriteria?.date,
        'end': createEndDate(searchCriteria?.date, searchCriteria?.duration),
        'duration': displaySearchCriteriaDuration('export', searchCriteria?.duration),
        'address': getAddress(searchCriteria?.location),
        'locationDetails': searchCriteria?.location,
        'services': exportCartItems(cartItems) || [],
        'budget': requestDetails?.budget || '',
        'details': requestDetails?.details || '',
        'promo_code': requestDetails?.promo_code || '',
        'time':displayDateAndDuration(searchCriteria?.date, searchCriteria?.duration),
        'created': new Date(),
        }
    console.log('SUBMIT ITEM: ', item)
    
    const JSONdata = toJSON(item)
    console.log('JSON: ', JSONdata)
    
    try {
        const response = await fetch(UserRequestRoute, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSONdata,
          });

        // Check response headers and status
        console.log('Response Headers: ', response.headers);
        console.log('Content-Type: ', response.headers.get('Content-Type'));
        console.log('Response Status: ', response.status);
        console.log('response.ok: ', response.ok);

          //FAILED
          if(!response.ok){
            const errorMessage = await response.text();
            throw new Error(errorMessage || 'Request failed');
          }

          //SUCCESS
          console.log("Success")

          // Log the full response text
          const responseText = await response.text();
          console.log('Full Response Text:', responseText);


         // Check if the response is actually JSON
          try {
              const data = JSON.parse(responseText);
              console.log('Data from PHP:', data);
              return { data, error: null };
          } catch (parseError) {
              console.error('Error parsing JSON:', parseError);
              throw new Error('Invalid JSON response');
          }            
    }
    catch (error) {
        //CAUGHT ERROR
        console.log("THERE IS AN ERROR: ", error);
        return {data: null, error};
    }
}

