import styled, {css, keyframes} from "styled-components";
import React, {useEffect, useRef } from 'react';

import {useAppNavBarContext} from "context/AppNavBarContext";
import DateTimeInput from "./DateTimeInput"
import DurationInput from "./DurationInput"
import LocationInput from "./LocationComponents/LocationInput";
import { useSearchContext } from 'context/AppContext/SearchContext';

function SearchInputContainer(){

const {closeInputEditMode, isInteractiveSearchBarVisible, closeSearchInputsViews, isLocationSearchVisible,isWhenSearchVisible,isDurationSearchVisible } = useAppNavBarContext()

// const isAnySearchVisible = isWhenSearchVisible || isDurationSearchVisible || isLocationSearchVisible;

console.log("isWhenSearchVisible:",isWhenSearchVisible," isDurationSearchVisible:",isDurationSearchVisible)


useEffect(() => {
    if (isInteractiveSearchBarVisible) {
      document.body.style.overflow = 'hidden';
      updateTempSearchCriteriaOnOpen()
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isInteractiveSearchBarVisible]);

    // Use a ref to track clicks within the container
    const containerRef = useRef(null);

    const handleClickOutside = (event) => {
        // Improved logging for debugging (optional)
        // console.group('Click Event');
        // console.log('event.target:', event.target);
        // console.log('containerRef.current:', containerRef.current);
        // console.groupEnd();  
        // Concise check for clicks outside the container
        if (containerRef.current && containerRef.current === event.target) {
            // closeSearchInputsViews();
            closeInputEditMode()
            // console.log('Container closed (click outside)');
          } else {
            // console.log('Container open (click inside)');
          }
      };

      //CONTEXT FOR ITEMS
      const {updateTempSearchCriteriaOnOpen, updateTempSearchCriteria} = useSearchContext()

      const setLocation = (place) => {
        updateTempSearchCriteria('location',place)
        closeSearchInputsViews()
      }

return (
    <>
        <Container ref={containerRef} onClick={handleClickOutside} $isOpen={isInteractiveSearchBarVisible}>
            <LocationInput isOpen={isLocationSearchVisible} setLocation={setLocation}/>
            <DateTimeInput isOpen={isWhenSearchVisible} />
            <DurationInput isOpen={isDurationSearchVisible}/>
        </Container>
    </>
)

}

export default SearchInputContainer

const Container = styled.div`
display:flex;
position: fixed;
height: 100%;
width: 100%; 
background-color: #00000080;
align-items: start;
justify-content: center;

${(props) =>
    props.$isOpen
      ? css`
          animation: ${fadeIn} 0.5s forwards;
          display:flex;
        `
      : css`
          animation: ${fadeOut} 0.3s forwards;
          animation-delay: 0s; /* No delay for fade out */
          display: none;
          opacity:0;
        `}
`
// KEYFRAMES
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;