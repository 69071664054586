import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';

//context
import {useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import { useBusinessServiceContext } from 'api/Business/BusinessService/BusinessServiceAPIContext';


import NavBarLogout from 'pages/business/header/NavBarLogout';

import {PageContentContainer} from "components/Page/Elements"

//Items
import { NAVBAR_HEIGHT } from "components/constants";
import { CALLTOACTION, SECTION, STANDARDBUTTON } from "components/standard";
import HoverImage from "components/HoverImage/HoverImage";

import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex} from 'components/emotionStyles'

import PopUpInfo from 'components/HoverImage/PopUpInfo';

//SIGNUP
import BusinessSignup from './signup/BusinessSignup';


//IMPORT SLICK CAROUSEL
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//components
import CheckoutError from 'pages/book/checkoutcomponents/checkoutError';
import SignUpSuccess from './signup/SignUpSuccess';

const Starter = {name:'Starter', price:'FREE', description:'A great way to give your clients access to GoghNow. Start earning for free.'};
const Pro = {name:'Pro', price:"$29.99", description:'Automated event booking solution. Ideal for businesses seeking an out-of-the-box solution to monetize their private spaces.'};
const Enterprise = {name:'Enterprise', price:"$99.99", description:'Custom automated event solution for enterprises. Designed for large businesses with complex processes, offering seamless integration and advanced features.'};



const trustby_images = [
    {alt:'CBRE',image: '/images/Business/logos/cbre.png'},
    {alt:'Greystar',image: '/images/Business/logos/greystar.png'},
    {alt:'Hoppin', image: '/images/Business/logos/hoppin.png'},
    {alt:'Crescent',image: '/images/Business/logos/crescent.png'},
    {alt:'Insperity',image: '/images/Business/logos/insperity.png'},
    {alt:'Kingsmen',image: '/images/Business/logos/kingsmen.png'},

]

const BusinessLanding = (props) => {


const {isMobile, isMedium, isLarge} = useScreenContext()
const {serviceSignup} = useBusinessServiceContext()



// useEffect(() => {
//     const handleMouseMove = (e) => {
//       setMousePosition({ x: e.pageX, y: e.pageY });
//     };
  
//     // Attach event listener when highlightedItem changes
//     document.addEventListener('mousemove', handleMouseMove);
  
//     // Clean up event listener
//     return () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//     };
//   }, [highlightedItem]); // Depend on highlightedItem to update mouse position when it changes
 

    
const servicesSectionRef = useRef(null);
const goToSignUpOfferings = () => {
    console.log("CLICKED!!!")
    servicesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
}

    const itemIsHighlighted = useState(null)

    const Hotels = {
        x: 2048/4096,
        y: 750/4096,
        title: "Hotels and Conference Centers",
        description: "Hotels and conference centers can efficiently book amenities for their events while their clients can seamlessly select and book amenities within their rented space.",
        ideal: ["Conferences", "Corporate Events", "Business Meetings", "Small/Large Gatherings"],
        image: "/images/Business/hotels.png"
    };
    
    const Corporate = {
        x: 2048/4096,
        y: 750/4096,
        title: "Corporate Spaces",
        description: "Corporate spaces can optimize event planning by easily booking the amenities they need.",
        ideal: ["Board Meetings", "Training Sessions", "Product Launches", "Team-Building Activities"],
        image: "/images/Business/corporate.png"
    };
    
    const Weddings = {
        x: 2048/4096,
        y: 750/4096,
        title: "Wedding Venues",
        description: "Wedding venues can streamline space bookings and offer easy selection of amenities for weddings and smaller occasions like cocktail hours or ceremonies.",
        ideal: ["Weddings", "Receptions", "Engagement Parties", "Anniversary Celebrations", "Cocktail Hours", "Private Events"],
        image: "/images/Business/wedding.jpeg"
    };
    
    const Restaurants = {
        x: 2048/4096,
        y: 750/4096,
        title: "Restaurants and Bars",
        description: "Restaurants and bars can quickly create themed events, and clients can easily book unique experiences through our one-stop-shop event services.",
        ideal: ["Themed Events", "Holidays", "Buyouts", "Business Lunches", "Client Meetings", "Networking Events", "Intimate Corporate Gatherings", "Private Parties"],
        image: "/images/Business/restaurant.jpg"
    };
    
    const Private = {
        x: 2048/4096,
        y: 750/4096,
        title: "Private Event Spaces",
        description: "Clients can effortlessly book spaces and event services.",
        ideal: ["Private Parties", "Family Gatherings", "Corporate Retreats", "Exclusive Celebrations"],
        image: "/images/Business/private.png"
    };
    
    const Clubhouses = {
        x: 2048/4096,
        y: 750/4096,
        title: "Social Clubs and Clubhouses",
        description: "Social clubs and clubhouses can easily create themed experiences, and members can seamlessly book spaces and event amenities.",
        ideal: ["Social Gatherings", "Club Meetings", "Community Events", "Casual Get-Togethers", "Seasonal Celebrations"],
        image: "/images/Business/social.jpeg"
    };
    
    const Communities = {
        x: 2048/4096,
        y: 750/4096,
        title: "Apartments and Communities",
        description: "Apartment complexes and community centers can effortlessly create themed experiences, while residents can easily book spaces and event amenities.",
        ideal: ["Community Gatherings", "Parties", "Picnics", "Social Events", "Resident Activities"],
        image: "/images/Business/apartment.jpeg"
    };
    
    const Museum = {
        x: 2048/4096,
        y: 750/4096,
        title: "Museums and Art Galleries",
        description: "Museums and art galleries can create unique event experiences by booking amenities, while clients can easily book spaces and amenities for their events.",
        ideal: ["Art Exhibits", "Cultural Events", "Private Viewings", "Educational Workshops, Private Events"],
        image: "/images/Business/museum.png"
    };



     const carouselSettings = {
        dots: false, // Add navigation dots for paging
        infinite: true, // Enable continuous sliding
        slidesToShow: 4.75, // Number of images visible per slide (adjust as needed)
        slidesToScroll: 1, // Number of images to slide on user interaction
        responsive: [
          // Optional responsive settings for different screen sizes
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2.75,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.75,
              slidesToScroll: 1,
            },
          },
        ],
      };

      //VENU TYPE
      const [highlightedItem, setHighlightedItem] = useState(null);
    //   const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseEnter = (data) => {
        console.log('handleMouseEnter: ',data)
        if (data.title !== highlightedItem?.title) {
          setHighlightedItem(data);
        }
      };
    
      const handleMouseLeave = () => {
        setHighlightedItem(null);
      };
    
      //THIS IS WHAT'S NEEDED FOR SIGNUP SHEET
      const [isSignupOpen, setSignupOpen] = useState(false);
      const [serviceType, setserviceType] = useState('');
      const formRef = useRef(null)
      const [invalidFields, setInvalidFields] = useState(new Set());
      const [submitting, setSubmitting] = useState(false);
      const [isErrorPopupVisible, setErrorPopupVisible] = useState(false);
      const [showSuccessPopup, setShowSuccessPopup] = useState(false);

      const [errorMessage, setErrorMessage] = useState('');
      const showErrorPopup = (message) => {
          setErrorMessage(message);
          setErrorPopupVisible(true);
        };
      
        const closeErrorPopup = () => {
          setErrorPopupVisible(false);
          setErrorMessage('');
        };

        

      const handleOpenSignup = (type) => {
        setSignupOpen(true);
        setserviceType(type)
        console.log(type)
      };
    
      const handleCloseSignup = () => {
        setShowSuccessPopup(false)
        setSignupOpen(false);
      };
    

      const handleSubmit = async (userData) => {

        // event.preventDefault();
        const isFormValid = formRef.current.checkValidity();
        if (isFormValid) {
        console.log('Form is valid! Submitting...');
        setSubmitting(true); // Set submitting state to true
        try {
            await handleCreateRequest(userData);
          } catch (error) {
            setSubmitting(false); // Reset submitting state on error
            console.error('Error submitting form:', error);
          }
        } else {
        // Do something when the form is not valid
        const invalidFields = getInvalidFields(formRef);
        showErrorPopup(`Invalid form fields: ${invalidFields.join(', ')}\nPlease check your inputs.`);
    }
    };

    const getInvalidFields = (formRef) => {
        const formElements = formRef.current.elements;
        const invalidFields = [];
    
        for (let i = 0; i < formElements.length; i++) {
            const field = formElements[i];
            if (!field.checkValidity()) {
                const fieldName = field.name || field.id;
                if (fieldName) {
                    invalidFields.push(fieldName);
                }
            }
        }
    
        return invalidFields;
    };
    const handleCreateRequest = async (data) => {
        try{
            const result = await serviceSignup(data, serviceType);
            console.log('Request successful:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            showErrorPopup(error.message);
        } finally {
            setSubmitting(false)
        }
      };

return (

    <>
    <NavBarLogout />
    <PageContentContainer className="content">
        <Content>
            
            <HeroSection>
                <HeroFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                    <HeroFlexItem1 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <CTA>
                            <HeroDescription>The event services platform that pays</HeroDescription>
                            <HeroText>Get more out of your event spaces with GoghNow</HeroText>
                            <HeroTextSubDescription><b>GoghNow for Business</b> empowers you to easily rent out your space, and let your clients effortlessly book all the amenities they need from one place.</HeroTextSubDescription>
                            {/* Enjoy profit sharing and increased revenue, all managed from an easy-to-use dashboard. Sign up for a free business account today! */}
                            <SignUpButton onClick={goToSignUpOfferings} >Sign up for a free!</SignUpButton>
                            {/* <ActionButton>Sign In</ActionButton> */}
                        </CTA>
                    </HeroFlexItem1>
                    <HeroFlexItem2 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        <ImageContainer>
                            <img src='/images/space2.jpeg' alt="space" />
                        </ImageContainer>
                    </HeroFlexItem2>
                </HeroFlexContainer>
            </HeroSection>
            <BenefitsSection>
                <BenefitsHeader>The #1 reason businesses use GoghNow is to boost their private events</BenefitsHeader>
                <OfferingContainer>
                    <Offering>
                        <OfferingImageContainer>
                            <img src='/images/Business/profit.png' alt="provider" />
                        </OfferingImageContainer>
                        <OfferingContentContainer>
                            <OfferingHeader>Increase Profits</OfferingHeader>
                            <OfferingDescription>Earn a share of the profits from every service your clients book.</OfferingDescription>
                            {/* <OfferingLink>Sign up today →</OfferingLink> */}
                        </OfferingContentContainer>
                    </Offering>
                    <Offering>
                        <OfferingImageContainer>
                            <img src='/images/Business/simplify.png' alt="provider" />
                        </OfferingImageContainer>
                        <OfferingContentContainer>
                            <OfferingHeader>Simplify Event Planning</OfferingHeader>
                            <OfferingDescription>Clients can easily choose from a variety of event services in one place.</OfferingDescription>
                            {/* <OfferingLink>Start earning →</OfferingLink> */}
                        </OfferingContentContainer>

                    </Offering>
                    <Offering>
                        <OfferingImageContainer>
                            <img src='/images/Business/integrate.png' alt="provider" />
                        </OfferingImageContainer>

                        <OfferingContentContainer>
                            <OfferingHeader>Streamline Bookings</OfferingHeader>
                            <OfferingDescription>Easily integrate our platform into your process for a seamless experience.</OfferingDescription>
                            {/* <OfferingLink>Sign up your business →</OfferingLink> */}
                        </OfferingContentContainer>

                    </Offering>
                </OfferingContainer>
            </BenefitsSection>


            <VenuesSection>
                <VenuesHeader>How GoghNow powers industries</VenuesHeader>
                    <VenueGridContainer>
                        <VenueGridItem className="lg" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage
                                    isHighlighted={highlightedItem?.title === Hotels.title}
                                    data={Hotels}
                                    onMouseEnter={() => handleMouseEnter(Hotels)}
                                    onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>
                        </VenueGridItem>

                        <VenueGridItem className="sm" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Corporate.title}
                                data={Corporate}
                                onMouseEnter={() => handleMouseEnter(Corporate)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>
                        </VenueGridItem>

                        <VenueGridItem className="sm" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Weddings.title}
                                data={Weddings}
                                onMouseEnter={() => handleMouseEnter(Weddings)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>
                        </VenueGridItem>

                        <VenueGridItem className="md" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Restaurants.title}
                                data={Restaurants}
                                onMouseEnter={() => handleMouseEnter(Restaurants)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem>

                        <VenueGridItem className="lg" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Private.title}
                                data={Private}
                                onMouseEnter={() => handleMouseEnter(Private)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                 
                        </VenueGridItem>

                        <VenueGridItem className="sm" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage 
                                isHighlighted={highlightedItem?.title === Clubhouses.title}
                                data={Clubhouses}
                                onMouseEnter={() => handleMouseEnter(Clubhouses)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem>

                        <VenueGridItem className="sm" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage
                                isHighlighted={highlightedItem?.title === Communities.title}
                                data={Communities}
                                onMouseEnter={() => handleMouseEnter(Communities)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem>

                        <VenueGridItem className="md" $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <VenueGridItemContainer>
                                <HoverImage
                                isHighlighted={highlightedItem?.title === Museum.title}
                                data={Museum}
                                onMouseEnter={() => handleMouseEnter(Museum)}
                                onMouseLeave={handleMouseLeave}
                                />
                            </VenueGridItemContainer>                    
                        </VenueGridItem>

                    </VenueGridContainer>
            </VenuesSection>



            <TrustedBySection>
                <TrustedByHeader>Trusted By</TrustedByHeader>
                <ContainerCarousel {...carouselSettings} prevArrow={<PrevArrow className="slick-prev"/>} nextArrow={<NextArrow className="slick-next"/>}>
                    {trustby_images.map((imageItem, index) => (
                        <ImageContainerCarousel key={index}>
                            <img src={imageItem.image} alt={imageItem.alt} />
                        </ImageContainerCarousel>
                    ))}
                </ContainerCarousel>

            </TrustedBySection>


            <ServicesSection ref={servicesSectionRef}>
                <ServicesHeader>Choose a Pricing Plan</ServicesHeader>
                <ServicesSubHeader>Get access to event services and start earning for free. Upgrade for more features.</ServicesSubHeader>
                <ServicesFlexContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

                    <ServicesFlexItem1 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}> 
                            <ServiceCategory>STARTER</ServiceCategory>
                            <InternalFlexContainer>
                                    <FlexItemTitle>FREE</FlexItemTitle>
                            </InternalFlexContainer>
                            <FlexItemOverview $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>A great way to give your clients access to GoghNow. Start earning for free.</FlexItemOverview>
                            <SignUpButton onClick={() => handleOpenSignup(Starter)} >Get it Now</SignUpButton>
                            <p><b>What's included:</b></p>
                            <FlexItemList>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Earn with every booking</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Dedicated event services selection link</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Basic support</p>
                                </FlexItemAttributeContainer>
                            </FlexItemList>
                    </ServicesFlexItem1>

                    <ServicesFlexItem2 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                            <ServiceCategory>PRO</ServiceCategory>
                            <InternalFlexContainer>
                                <FlexItemTitle>$29.99</FlexItemTitle>
                                <FlexItemTitleMetric> / per month</FlexItemTitleMetric>
                            </InternalFlexContainer>
                            <FlexItemOverview $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Automated event booking solution. Ideal for businesses seeking an out-of-the-box solution to monetize their private spaces.</FlexItemOverview>
                            <SignUpButton onClick={() => handleOpenSignup(Pro)} >Get it Now</SignUpButton>
                            <p><b>Everything in FREE plus:</b></p>
                            <FlexItemList>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Seamless booking forms</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Automated and branded communications</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Automated event services link for each client event</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Dashboard tracking events & services needs</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Priority support</p>
                                </FlexItemAttributeContainer>
                            </FlexItemList>
                    </ServicesFlexItem2>


                    <ServicesFlexItem3 $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                        
                            <ServiceCategory>ENTERPRISE</ServiceCategory>
                            <FlexItemTitleMetric> ( starting at )</FlexItemTitleMetric>
                            <InternalFlexContainer>
                                <FlexItemTitle>$99.99</FlexItemTitle>
                                <FlexItemTitleMetric> / per month</FlexItemTitleMetric>
                            </InternalFlexContainer>
                            <FlexItemOverview $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>Custom automated event solution for enterprises. Designed for large businesses with complex processes, offering seamless integration and advanced features.</FlexItemOverview>
                            <SignUpButton onClick={() => handleOpenSignup(Enterprise)} >Get it Now</SignUpButton>
                            <p><b>Everything in PRO plus:</b></p>
                            <FlexItemList>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Customize the entire GoghNow booking experience</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Customize GoghNow process workflows</p>
                                </FlexItemAttributeContainer>
                                <FlexItemAttributeContainer>
                                    <FlexItemListImgContainer><img src='/images/Business/checkmark-grn.png' alt="provider" /></FlexItemListImgContainer>
                                    <p>Dedicated account manager</p>
                                </FlexItemAttributeContainer>
                            </FlexItemList>
                    </ServicesFlexItem3>


                </ServicesFlexContainer>
            </ServicesSection>

            {/* Popup Info */}
            {highlightedItem && (
                <div onMouseEnter={(e)=>{e.stopPropagation()}} onMouseLeave={(e)=>{e.stopPropagation()}}>
                    <PopUpInfo
                        noBackground={!isMobile}
                        image={highlightedItem.image}
                        title={highlightedItem.title}
                        description={highlightedItem.description}
                        // Appear={true}
                        SlideUp={true} // Add this prop to enable slide-up animation
                        list={highlightedItem.ideal}
                    />
                </div>
            )}
        </Content>
    </PageContentContainer>

    <BusinessSignup 
        isOpen={isSignupOpen} 
        onClose={handleCloseSignup} 
        onSubmit={handleSubmit}
        formRef={formRef}
        submitting={submitting}
        name= {serviceType.name}
        description = {serviceType.description}
    />
        {showSuccessPopup && 
            <SignUpSuccess onClose={handleCloseSignup}/>
        }
        {isErrorPopupVisible && (
        <CheckoutError
          message={errorMessage}
          onClose={closeErrorPopup}>
        </CheckoutError>
      )}
     

    </>

)

}

export default BusinessLanding;

const Content = styled.div`
width: 100%;
height: 100%;
`
const HeroSection = styled(SECTION)`
background-color: black;

`;

const HeroFlexContainer = styled.div`

${mobileDisplayContentVertical};
    display: flex;
    width: 100%;
    padding: 15px 36px;
    max-width: 1280px;
    gap: 36px;

`
const HeroFlexItem1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '50%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    height: auto !important;
`
const HeroFlexItem2 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return '100%';
        if (props.$isMedium) return '50%';
        if (props.$isLarge) return '50%';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;

`
const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    // position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
//Container joint
const CTA = styled(CALLTOACTION)`
// padding-bottom: calc(${NAVBAR_HEIGHT} + ${NAVBAR_HEIGHT});
gap: 50px;
align-items: start !important;
p, h1{
margin: 0px;
}
`;

const SignUpButton = styled(STANDARDBUTTON)`
width: 100% !important;
height: 60px;
text-align: center;
flex-shrink: 0 !important; // Prevents the element from shrinking
flex-grow: 0 !important; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
justify-content: center !important;
margin: 10px 5px 20px 5px;
`
const HeroText = styled.h1`
color: #FFFFFF;
font-size: 3.5em;
font-weight: 700;
margin-top: 0px;
line-height: 50px;
`
const HeroDescription = styled.p`
color: #FFFFFF80;
font-size : .8em;
margin: 0 0 24px;
line-height: 1.5em;
// letter-spacing: .75px;
max-width: 400px;
margin-bottom: 25px;
// background-color: #FBAE6270;
// padding: 10px 25px;
font-weight: 400;
border-radius: 15px;
`

const HeroTextSubDescription = styled.p`
color: #FFFFFF75;
font-size: 1.25em;
// font-weight: 900;
margin-top: 0px;
line-height: 115%;
// letter-spacing: 1.1px;

`




//BENEFITS SECTION
const BenefitsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 36px;
  color: black;
  min-height: 50vh; /* Ensures the div is at least 50% of the viewport height */
  box-sizing: border-box; /* Ensures padding is included in the height calculation */
  padding: 50px 36px;
`;

const BenefitsHeader = styled.h2`
  text-align: center;
  letter-spacing: .10px;
  font-weight: 700;
  line-height: 95%;
`;

const OfferingContainer = styled.div`

${mobileDisplayContentVertical};
display: flex;
width: 100%;
gap: 36px;
// justify-content: space-around;
padding: 25px 0px;

@media (max-width: 769px) {
    gap: 15px;
    // padding: 20px 0px;
}
`

const Offering = styled.div`
${mobileDisplayContentHorizontal};

display: flex;
flex: 1 1 calc(33.3% - 36px);
flex-direction: column;
justify-content: center;
align-items: start;
gap: 15px;

@media (max-width: 769px) {
    gap: 15px;
    padding: 20px 0px;
    align-items: center;

}
`

const OfferingImageContainer = styled.div`
margin-bottom: 0px;

width: 20%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: flex;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 0;
    width: 50%;
}
`

const OfferingContentContainer = styled.div`
display: flex;
flex-direction:column;
width: 100%;
 @media (max-width: 769px) {
    text-align: left;
    padding-left: 10px;
    flex-grow: 1;

        p{
    margin: 0px;
    }
}
`

const OfferingHeader = styled.h3`
color:black;
font-weight: 700;
line-height: 90%;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
`
const OfferingDescription = styled.p`
padding-top: 0px;
margin-top: 0px;
color:black;
font-size: 18px;
`

//This was the link error
const OfferingLink = styled.div`
color: #FBAE62;
font-weight: 700;
font-size: 18px;
cursor: pointer;
`


//GOGHNOW POWERS ALL VENUES
const VenuesSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 36px;
background-color: #000000;
  color: black;
  height: auto;
  align-items: center;
//   box-sizing: border-box; /* Ensures padding is included in the height calculation */
`
const VenuesHeader = styled.h1`
font-size: 2.5em;
  text-align: center;
  color: #FFFFFF;
letter-spacing: .10px;
font-weight: 700;
  line-height: 95%;
  padding: 50px 0px;

`;

const VenueGridContainer = styled.div `
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    width: 100%;
    max-width: 1000px;

    height: auto;
    gap: 5px;
    // grid-template-rows: 1fr 1fr;
    // box-sizing: border-box;
    // background-color: #CCC;
`

const VenueGridItem = styled.div `

    // background-color: #00000040;
    // border: 1px solid #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    // font-size: 1.5em;
    overflow: hidden;
    min-width: 100%;
    // min-height: 100%;
    position: relative;
    cursor: pointer;

    
/* Custom positions */
  &.lg { 
    aspect-ratio: ${props => {
        if (props.$isMobile) return '1 / 1';
        if (props.$isMedium) return '1 / 1';
        if (props.$isLarge) return '1 / 1';
        return '1 / 1'; // Default to large if none match
    }}; 
    grid-row: ${props => {
        if (props.$isMobile) return 'span 1';
        if (props.$isMedium) return 'span 2';
        if (props.$isLarge) return 'span 2';
        return 'span 2'; // Default to large if none match
    }}; 
    grid-column: ${props => {
        if (props.$isMobile) return 'span 2';
        if (props.$isMedium) return 'span 2';
        if (props.$isLarge) return 'span 2';
        return 'span 2'; // Default to large if none match
    }};
  }
  &.md { 
    aspect-ratio: ${props => {
        if (props.$isMobile) return '1 / 1';
        if (props.$isMedium) return '2 / 1';
        if (props.$isLarge) return '2 / 1';
        return '2 / 1'; // Default to large if none match
    }}; 
    grid-row: ${props => {
        if (props.$isMobile) return 'span 1';
        if (props.$isMedium) return 'span 1';
        if (props.$isLarge) return 'span 1';
        return 'span 2'; // Default to large if none match
    }}; 
    grid-column: ${props => {
        if (props.$isMobile) return 'span 2';
        if (props.$isMedium) return 'span 2';
        if (props.$isLarge) return 'span 2';
        return 'span 2'; // Default to large if none match
    }};
  }
  &.sm { 
    aspect-ratio: ${props => {
            if (props.$isMobile) return '1 / 1';
            if (props.$isMedium) return '1 / 1';
            if (props.$isLarge) return '1 / 1';
            return '1 / 1'; // Default to large if none match
        }}; 
    grid-row: ${props => {
        if (props.$isMobile) return 'span 1';
        if (props.$isMedium) return 'span 1';
        if (props.$isLarge) return 'span 1';
        return 'span 1'; // Default to large if none match
    }}; 
    grid-column: ${props => {
        if (props.$isMobile) return 'span 2';
        if (props.$isMedium) return 'span 1';
        if (props.$isLarge) return 'span 1';
        return 'span 1'; // Default to large if none match
    }};
  }

`

const VenueGridItemContainer = styled.div`
  position: absolute; /* Added to position the container absolutely */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const VenueGridItemContainerHorizontal = styled.div`
display: flex;
width: 100%;
overflow: hidden;
align-items: center;
justify-content: center;
aspect-ratio: 2 / 1;
`


//Trusted By Section
const TrustedBySection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
margin: 0vh 0px 5vh 0px;
height: auto;
// background-color: #CCC;
`

const TrustedByHeader = styled.h1`
font-size: 2.5em;
text-align: center;
color: black;
letter-spacing: .10px;
font-weight: 1000;
line-height: 95%;
padding: 50px 0px;
`

const ContainerCarousel =  styled(Slider)`

display: flex !important;
width: 100%;
overflow: hidden;

/* the slides */
  .slick-slide {
      padding: 0px 25px 0px 15px;
  }

  /* the parent */
  .slick-list {
      margin: 0 -25px;
  }
.slick-dots {
    position: absolute; /* Make dots absolute within the carousel */
    bottom: 10px; /* Adjust the bottom position as needed */
    left: 50%; /* Center the dots horizontally */
    transform: translateX(-50%); /* Offset to compensate for centering */
    display: flex; /* Ensure horizontal alignment */
    justify-content: center; /* Center dots within the carousel width */

    li {
        margin: -5px;
    }
  }

  &:hover .prev-button,
  &:hover .next-button {
    opacity: 1;
    color: black;
  }
`
const ImageContainerCarousel = styled.div`
height: auto;
aspect-ratio: 2 / 1;
overflow: hidden;
// padding: 15px;
// margin: 15px;
max-width: 100%;
display:flex !important;
justify-content: center;
align-items: center;
border: solid 1px white;
background-color: white;
img {
    display: flex;
   max-width: 100%;  /* Fill container horizontally */
}
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  opacity: 0;
  
//   display: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);

  }

  font-weight: 600;
`;

const PrevArrowButton = styled(ArrowButton)`
  left: 10px;
`;

const NextArrowButton = styled(ArrowButton)`
  right: 10px;
`;

const PrevArrow = ({ onClick }) => (
  <PrevArrowButton className='prev-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     ←
  </PrevArrowButton>
);

const NextArrow = ({ onClick }) => (
  <NextArrowButton className='next-button' type="button" onClick={(e) => { e.stopPropagation(); onClick(e); }}>
     →
  </NextArrowButton>
);






//Services By Section
const ServicesSection = styled.div`
display: flex;
flex-direction: column;
padding: 36px 36px;
margin: 0vh 0px 25vh 0px;
height: auto;
// background-color: #CCC;
align-items: center;
`

const ServicesHeader = styled.h1`
font-size: 2.5em;
text-align: center;
color: black;
letter-spacing: .10px;
font-weight: 1000;
line-height: 95%;
padding: 50px 0px;
`
const ServicesSubHeader = styled.p`
// font-size: 2.5em;
text-align: center;
color: black;
letter-spacing: .10px;
font-weight: 1000;
line-height: 95%;
margin: 0px;
`

const ServicesFlexContainer = styled.div`

${mobileDisplayContentVertical};
    display: flex;
    width: 100%;
    padding: 36px 36px;
    max-width: 1280px;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;
`
const ServicesFlexItem1 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: left;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(50% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    height: auto !important;
    background-color: #3F3F3F;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;

`
const ServicesFlexItem2 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(50% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;

`

const ServicesFlexItem3 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: ${props => {
        if (props.$isMobile) return 'calc(100% - 15px)';
        if (props.$isMedium) return 'calc(50% - 15px)';
        if (props.$isLarge) return 'calc(33.3% - 15px)';
        return '50%'; // Default to large if none match
    }}; 
    // height: 50vh !important;
    background-color: #3F3F3F;
    padding: ${props => {
        if (props.$isMobile) return '25px';
        if (props.$isMedium) return '36px';
        if (props.$isLarge) return '36px';
        return '50%'; // Default to large if none match
    }}; 
    border-radius: 20px;
`



const InternalFlexContainer = styled.div`
display: flex;
align-items: end;
margin-bottom: 10px;
`

const FlexItemOverview = styled.p`
min-height: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '6.5em';
    if (props.$isLarge) return '6.5em';
    return '0px'; // Default to large if none match
}}; 
`
const FlexItemTitle = styled.h1`
color: #FFFFFF;
font-size: 3.5em;
font-weight: 700;
margin-top: 0px;
line-height: 50px;
margin: 0px;
`

const FlexItemTitleMetric = styled.p`
color: #FFFFFF80;
font-size : .8em;
line-height: 1.5em;
max-width: 400px;
font-weight: 400;
border-radius: 15px;
margin: 0px;

`

const ServiceCategory = styled.p`
font-weight: bold;
`

const FlexItemList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-bottom: 20px;

    p {
    margin: 0px;
    }
`;

const FlexItemAttributeContainer = styled.li`
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    justify-content: start;
`;

const FlexItemListImgContainer = styled.div`
    display: flex;
    height: 32px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    img {
    display: block;
    width:100%;
}
`;