import { css } from '@emotion/react';


export const buttonRoundCloseStyle = css`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  font-weight: 600;
  font-size: 1.5em;
  border: 1px solid transparent;
  cursor: pointer;
  background-color: #EEE;
  color: #BBB;
  &:hover {
    background-color: #CCC;
    color: white;
  }
`;

export const buttonSubmitStyle = css`

    padding: 10px 20px;
    background-color: #FBAE62;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
    text-align: center;
    height: 60px;

    &:hover {
        background-color: #FBAE6260;
}
`