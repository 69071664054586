import styled from "styled-components";
import { useAuthContext } from "../../context/AppContext/AuthContext"

import ProviderApp from "./content/ProviderApp";
import ProviderLanding from "./content/ProviderLanding";
import { PageContainer } from "components/Page/Elements";


const Provider = (props) => {

    const { isLoggedIn } = useAuthContext() //UPDATE TO PROVIDER

    return (
    <>
        <PageContainer className="page-container">
            {isLoggedIn ? (
                // <AppNavBarManagerProvider>
                <ProviderApp />
                // </AppNavBarManagerProvider>
            ) : (
                <ProviderLanding />
            )}
        </PageContainer>
    </>
    );
};


export default Provider;