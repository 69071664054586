import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
// import config from 'config'

import { useGoogleMapsAddressContext } from 'context/AppContext/GoogleMapsAddressContext';

//SHOULD BE LOCATION SHIT LATER
import {getLine1, getLine2} from 'context/AppContext/SearchContext';


export const GoogleMapsAddressInput = ({
  // onSearchSubmit,
  onPlaceSelected,
  onFocus,
  onChange,
  onBlur,
  value,
  className,
  disableAutocomplete = false,
  placeholder = "Enter event address",
  id = 'search',
  required = false,

}) => {

  //OR JUST EXPORT FROM THE CONTEXT
  const {loadGoogleMapsApi, extractAddress, fetchPredictions} = useGoogleMapsAddressContext()


  const searchInput = useRef(null);
  const [searchAddress, setSearchAddress] = useState(value || '');


  useEffect(() => {

    const onChangeAddress = (autocomplete) => {
      const place = autocomplete.getPlace();
      if (!place.geometry) {
        console.log("No details available for input: '" + place.name + "'");
        return;
      }
      const addressObject = extractAddress(place);
      const formattedAddress = getLine1(addressObject) + getLine2(addressObject);
      setSearchAddress(formattedAddress);
      if (onPlaceSelected) {
        onPlaceSelected(addressObject);
      }
    };


    const initAutocomplete = () => {
      if (!searchInput.current || disableAutocomplete) return;

      const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
      autocomplete.setFields(['address_component', 'geometry']);
      autocomplete.addListener('place_changed', () => onChangeAddress(autocomplete));
    };

    loadGoogleMapsApi()
      .then(initAutocomplete)
      .catch(error => {
        console.error('Failed to load Google Maps API:', error);
      });
  }, [disableAutocomplete]);

  useEffect(() => {
    setSearchAddress(value);
  }, [value]);


  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchAddress(inputValue);
    fetchPredictions(inputValue)
    onChange && onChange(e);
  };

  return (
    <>
      <SearchInput
      className={`input-please-work ${className}`}
      ref={searchInput}
        value={searchAddress || ''}
        onChange={handleInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type="text"
        id={id}
        placeholder={placeholder}
        required={required}
      />
      
    </>
  );
};

export default GoogleMapsAddressInput;



const SearchInput = styled.input`
    height: 60px;
    border-radius: 20px;
    font-size: 18px;
    width: 100%;
    border: 1px solid transparent;
    padding-left: 25px;
    // padding-right: 25px;
    outline:none;
    background-color: transparent;
    color: black;
    `









    //ADDED , LIKELY DON"T NEED
    // const reverseGeocode = ({ latitude: lat, longitude: lng}) => {
    //     const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    //     searchInput.current.value = "Getting your location...";
    //     fetch(url)
    //         .then(response => response.json())
    //         .then(location => {
    //           const place = location.results[0];
    //           const _address = extractAddress(place);
    //           setSearchAddress(_address);
    //           searchInput.current.value = _address.plain();
    //         })
    //   }
    
      //ADDED, LIKELY DON"T NEED
    //   const findMyLocation = () => {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(position => {
    //         reverseGeocode(position.coords)
    //       })
    //     }
    //   }
