import styled from "styled-components";

//SECTION
export const SECTION = styled.div`
overflow: hidden;
display: flex;
flex-direction: row;
width: 100%;
justify-content: center;
align-items: center;
padding: 50px 36px;
// background-color: white;
// text-align: center;
`

export const CALLTOACTION = styled.div`
max-width: 650px;
flex-wrap: wrap;
flex-direction: column;
justify-content: center;
// margin-top: 0;
align-items: center;
text-align; center;
// margin-right: auto;
// margin-left: auto;
transition-timing-function: ease-out;
transition: opacity 0.2s;
width: 100%;
display: flex;
`;

export const AddButton = styled.button `
display: flex;
height: 35px;
width: 35px;
background-color: rgba(251, 174, 98, 0.6);
color: black;
border-radius: 50%;
top : 0px; 
right: 0px;
position: absolute;
align-items: center;
justify-content: center;
border: 1px solid transparent;
box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);
cursor: pointer;
margin: 10px;

&:hover{
    background-color: rgba(251, 174, 98, 1);
}`

export const STANDARDBUTTON = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 15px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 2px solid #FBAE62;
color: #FBAE62;
cursor: pointer;
background-color: transparent;

&:hover{
    background-color: #FBAE6260;
}
`


//NEW ELEMENTS
export const ActionButton = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 50px;
border-radius: 25px;
border: 1px solid #CCC;
background-color: #FBAE62;
color: white;
cursor: pointer;
margin-top: 25px;
height: 50px;

&:hover{
    background-color: #FBAE6260;
}
`