import React, { createContext, useState, useContext, useEffect } from 'react';
import { NAVBAR_HEIGHT } from "components/constants";

//emotion
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import { keyframes } from '@emotion/react'

//components
import { useScreenContext } from 'context/AppContext/ScreenContext';

// import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"
import {GoogleMapsAddressInput} from "components/Inputs/GoogleMapsAddressInput"

import TextField from '@mui/material/TextField';
import { ActionButton } from 'components/standard';
import { displaySearchCriteriaLocation } from 'context/AppContext/SearchContext';


const BusinessSignup = ({isOpen, onClose, onSubmit,submitting, data = {}, formRef, name = "Signup", description ="Sigup today!"}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()

    const [error, setError] = useState(false); //THIS SHOULD BE HANDLED OUTSIDE
    const [user, setUser] = useState(data)
    // const [submitting, setSubmitting] = useState(false);
    const [place, setPlace] = useState({})
    useEffect(() => {
        if (isOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
          setUser({data});
        }
    
        // Cleanup function to remove the style when the component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [isOpen]);

    //   useEffect(() => {
    //     // Initialize user with data if available when component mounts or data changes
    //     if (data) {
    //         setUser(prevState => ({
    //             ...prevState,
    //             ...data
    //         }));
    //     }
    // }, [data]);


      const handleInputChange = (e) => {
        const { id, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [id]: value
        }));

        console.log('CHANGE: ',user)
    };


      const handleSearchSubmit = (address) => {

        //maybe use a try and if error, means no location came back, display that
        console.log('address:', address);
        // loginAsGuestWithAddress(address)
      };
    
      // Function to handle place selection
      const handlePlaceSelected = (place) => {
        console.log('Place selected:', place);
        setUser(prevState => ({
            ...prevState,
            address: displaySearchCriteriaLocation('searchBarExpanded', place),
            lat: place.lat,
            lng: place.lng
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(user);
    };

    const handlePanelClick = (event) => {
        event.stopPropagation(); // Prevent propagation
      };


      return (
         isOpen && (
        <CloseOnTapOverlayContainer $isOpen={isOpen} onClick={onClose}>
            <Panel onClick={handlePanelClick} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
                <HeaderPanel>
                    <CloseButton onClick={onClose}>X</CloseButton>
                </HeaderPanel>
                <BodyPanel>
                    <FormContainer>
                        <form id="FormID" ref={formRef} onSubmit={handleSubmit}>
                            <SubHeading>{name}</SubHeading>
                            <SubHeadingDetails>{description}</SubHeadingDetails>
                            <Space/>
                            <SectionHeadingTitle>Company</SectionHeadingTitle>
                            <TextField
                                id = "companyname"
                                label="Company Name"
                                type="companyname"
                                variant="outlined"
                                value={user.companyname || ''}
                                onChange={handleInputChange}
                                error={error}
                                helperText={error ? "This field is required" : ""}
                                fullWidth
                                size="small"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <GoogleMapsAddressInputSpecial
                                onSearchSubmit={handleSearchSubmit}
                                onPlaceSelected={handlePlaceSelected}
                                disableAutocomplete={false}
                                placeholder="Company Address"
                                onChange={handleInputChange}
                                value={user.address || ''}
                                id= "address"
                                required = {true}
                            />
                            {/* onFocus={LocationOnFocus} onBlur={LocationOnBlur} className='test' */}
                            <SectionHeadingTitle>Representative</SectionHeadingTitle>
                            <TextField
                                id = "name"
                                label="Name"
                                type="name"
                                variant="outlined"
                                value={user.name || ''}
                                onChange={handleInputChange}
                                error={error}
                                helperText={error ? "This field is required" : ""}
                                fullWidth
                                size="small"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                            id='email'
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={user.email || ''}
                            onChange={handleInputChange}
                            error={error}
                            helperText={error ? "Please enter a valid email address" : ""}
                            fullWidth
                            size="small"
                            margin="normal"
                            required
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />

                            <TextField
                                id="phone"
                                label="Phone"
                                type="tel"
                                variant="outlined"
                                value={user.phone || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                margin="normal"
                                required
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </form>
                    </FormContainer>
                </BodyPanel>
                    <CheckOutButton
                        onClick={handleSubmit}
                        disabled={submitting}
                        style={{ width: '100% important!' }}>

                        <p>{submitting ? 'Sending...' : `Signup`}</p>
                    </CheckOutButton> 
            </Panel>
          {/* Add your signup form or other content here */}
        </CloseOnTapOverlayContainer>
         )
      );

}

export default BusinessSignup;



const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;



//STYLE
const CloseOnTapOverlayContainer = styled.div`
  display:flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-width: 100vw;
  height: 100vh;
  background-color: #3F3F3F75 ;
//   z-index: 50;
  padding: 5px;
//   gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.3s ease-in-out;
  overflow: hidden; /* Prevent scrolling when modal is open */
`;


const Panel = styled.div`
display: flex;
flex-direction: column;
overflow-y: scroll;
width: ${props => {
    if (props.$isMobile) return '90%';
    if (props.$isMedium) return '82.5%';
    if (props.$isLarge) return '60%';
    return '60%'; // Default to large if none match
  }}; 
height: ${props => {
    if (props.$isMobile) return '80%';
    if (props.$isMedium) return '70%';
    if (props.$isLarge) return '70%';
    return '80%'; // Default to large if none match
  }}; 
max-width: 1120px;
border-radius: 20px;
background-color: white;
padding: 15px;
gap: 10px;
align-items: center;
border: 1px solid black;
box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
animation: ${slideUp} 0.3s ease-in-out;

`
const HeaderPanel = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
justify-content: end;
align-items: center;
`

const CloseButton = styled.button`
   
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        font-weight: 600;
        font-size: 1.5em;
        background-color: transparent;
        border: 1px solid transparent;
        &:hover{
            background-color: #CCCCCC;
        }
        cursor: pointer;
}`

const BodyPanel = styled.div`
display: flex;
height: auto;
min-height: calc(100% - (2 * ${NAVBAR_HEIGHT}));
width: 100%;
flex-wrap: wrap;
gap: 50px;
flex-grow: 1;
overflow-y: scroll;
`

const FormContainer = styled.div`
display: flex;
flex-direction: column; 
align-items: center;
padding: 15px;
width: 100%;
// background-color: yellow;
`


//ADDED STUFF
const SubHeading = styled.h2`
color: black;
margin-bottom: 0px;
margin-top: 0px;
`
const SubHeadingDetails = styled.p`
color: #00000060;
margin-bottom: 0px;
margin-top: 0px;
`

const SectionHeadingTitle = styled.h4`
color: #00000055;
`
const Space = styled.div`
display:flex;
width:100%;
height: 1px;
background-color: #000000;
margin: 15px 0px;
`

const SpaceEmpty = styled.div`
display:flex;
width:100%;
height: 1px;
// background-color: #000000;
margin: 7.5px 0px;
`

//ITEMS
const GoogleMapsAddressInputSpecial = styled(GoogleMapsAddressInput)`
    background-color: transparent;
    color: black;
    font-size: .875em;

    &.input-please-work {
        background-color: transparent !important;
        border: 1px solid #BBB;
        border-radius: 10px;
      }
`

const CheckOutButton = styled.button`
display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-items: center;
justify-content:center;
width: auto;
padding: 0px 25px;
flex-shrink: 0; // Prevents the element from shrinking
flex-grow: 1; // Allows the element to grow if necessary
flex-basis: auto; // Basis is based on the content size
border-radius: 25px;
border: 2px solid #FBAE62;
color: #FBAE62;
cursor: pointer;
background-color: transparent;
width: 50%;

&:hover{
    background-color: #FBAE6260;
}

`