import React, { createContext, useState, useContext, useEffect } from 'react';
// import { useCart } from './CartContext';
import { useAuthContext } from './AppContext/AuthContext';


const AppNavBarContext = createContext();

export const useAppNavBarContext = () => useContext(AppNavBarContext);
// export const WidgetManagerContext = createContext();


export const AppNavBarManagerProvider = ({ children}) => {

    const { resetAllContexts} = useAuthContext();
    //SEARCH BAR STATES
    const [isInteractiveSearchBarVisible, setIsInteractiveSearchBarVisible] = useState(false);
    const [isCompactSearchBarVisible, setIsCompactSearchBarVisible] = useState(true);
    const toggleInteractiveSearchBar = () => {
        console.log("searchBarBeingToggled")
        setIsInteractiveSearchBarVisible(prevState => !prevState);
        setIsCompactSearchBarVisible(prevState => !prevState);
    }

     //Reset Context
    const resetContext = () => {
        closeInputEditMode();
        closeCartMenu();
    }


    useEffect(() => {
    if (resetAllContexts) {
        resetContext();
    }
    }, [resetAllContexts]);


    const openExpandedSearch = ({ location = false, when = false, duration = false } = {}) => {
       
        setIsInteractiveSearchBarVisible(true);
        setIsCompactSearchBarVisible(false);

        if (location){
            toggleLocationSearch();
            return;
        }
        if (when){
            toggleWhenSearch();
            return;
        }

        if (duration){
            toggleDurationSearch();
            return;
        }

    }
    const closeInputEditMode = () => {
        setIsInteractiveSearchBarVisible(false);
        setIsCompactSearchBarVisible(true);
        closeSearchInputsViews();
    }



    //SEARCH INPUT STATES
    const [isLocationSearchVisible, setIsLocationSearchVisible] = useState(false);
    const [isWhenSearchVisible, setIsWhenSearchVisible] = useState(false);
    const [isDurationSearchVisible, setIsDurationSearchVisible] = useState(false);

    const toggleLocationSearch = () => {
        setIsLocationSearchVisible(prevState => !prevState);
        setIsWhenSearchVisible(false)
        setIsDurationSearchVisible(false)
        // console.log("toggleLocationSearch: ")
    }
    //below is a special case for address bar
    const setIsLocationSearchVisibleToVisible = () => {
        setIsLocationSearchVisible(true);
        setIsWhenSearchVisible(false)
        setIsDurationSearchVisible(false)
    }

    const toggleWhenSearch = () => { 
        setIsLocationSearchVisible(false)
        setIsWhenSearchVisible(prevState => !prevState);
        setIsDurationSearchVisible(false)
        // console.log("toggleWhenSearch: ",isWhenSearchVisible)

    }
    const toggleDurationSearch = () => {
        setIsLocationSearchVisible(false)
        setIsWhenSearchVisible(false)
        setIsDurationSearchVisible(prevState => !prevState);
        // console.log("toggleDurationSearch")

    }

    const closeSearchInputsViews = () => {
        setIsLocationSearchVisible(false);
        setIsWhenSearchVisible(false);
        setIsDurationSearchVisible(false);

        //CLEAR THE SEARCH CRITERIA JOINT
    }

    //CART MENU STATES
    const [isCartMenuOpen, setIsCartMenuOpen] = useState(false);
    const toggleCartMenu = () => {
        setIsCartMenuOpen(prevState => !prevState);
        console.log('isCartMenuOpen', isCartMenuOpen);
    };
    const closeCartMenu = () => setIsCartMenuOpen(false);


    // const { cartItems } = useCart();


    return (
        <AppNavBarContext.Provider
            value={{ 
                //SEARCH ITEMS HERE
                isInteractiveSearchBarVisible,
                isCompactSearchBarVisible,
                toggleInteractiveSearchBar,
                openExpandedSearch,
                closeInputEditMode,
                //CART MENU STATES
                isCartMenuOpen,
                toggleCartMenu,
                closeCartMenu,
                //SEARCH INPUT STATES
                setIsLocationSearchVisibleToVisible,
                isLocationSearchVisible,
                isWhenSearchVisible,
                isDurationSearchVisible,
                toggleLocationSearch,
                toggleWhenSearch,
                toggleDurationSearch,
                closeSearchInputsViews

             }}
        >
            {children}
        </AppNavBarContext.Provider>
    );
};