import React, {useState} from 'react';
import styled from '@emotion/styled';

// import {useState, useEffect, useRef } from "react";
// import { useNavigate, Link } from 'react-router-dom';

import { NAVBAR_HEIGHT } from "components/constants";

import { useScreenContext } from 'context/AppContext/ScreenContext';
import InputContainer from 'components/Containers/InputContainer';

import SignupPhoneInput from 'components/Form/SignupPhoneInput';
import SignupPhoneAccessCode from 'components/Form/SignupPhoneAccessCode';
import SignupBusinessInfo from 'components/Form/SignupBusinessInfo';
import {buttonRoundCloseStyle, buttonSubmitStyle} from 'components/Buttons/ButtonStyles'

const Signup = (props) => {

    //Screen Size
    const {isMobile, isMedium, isLarge} = useScreenContext()


    const [formData, setFormData] = useState({});
    const [screen, setScreen] = useState(0)

    const handleInputChange = (value, field) => {
        // Your logic to update state
        console.log(`Value: ${value}, Field: ${field}`);

        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));

        console.log('formData: ',formData)

    };
    const handlePrevious = () => {
        setScreen(prevScreen => prevScreen - 1);
        console.log('Previous', screen - 1);
    }

    const handleNext = () => {
        if(screen == 0){
            console.log("TRIGGER CREATION OF NUMBER")
        }
        else if(screen == 1){
            console.log("VALIDATE ACCESS CODE THEN GO")

        }else if (screen == 2){
            console.log("CREATE ACCOUNT THEN GO")
        }
        else if(screen == 3){
            console.log("exit and navigate to dashboard baby!")
        }
        setScreen(prevScreen => prevScreen + 1);
        console.log('Next', screen + 1);
    }
    return (
    <>

        <Container>
            <Content $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>


                <InputContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>

                    <HeaderContainer>
                        <FlexItem>
                        {screen > 0 && (
                            <ButtonClose onClick={handlePrevious}>←</ButtonClose>
                        )}
                        </FlexItem>
                        <FlexItem>
                            <LogoContainer>
                                <img src='/images/cta-logo-one.svg' alt="GoghNow" />
                            </LogoContainer>
                        </FlexItem>
                        <FlexItem></FlexItem>
                    </HeaderContainer>
                    <FormContent>
                        {/* STEP 1 */}
                            {screen == 0 && (
                                <>
                                    <SubText><p>Phone</p></SubText>
                                    <MainText><h4>Add your phone number</h4></MainText>
                                    <SignupPhoneInput data={formData} onChange={handleInputChange}></SignupPhoneInput>
                                </>
                            )}
                        {/* STEP 2 */}
                            {screen == 1 && (
                            <>
                                <SubText><p>Access Code</p></SubText>
                                <MainText><h4>Enter your access code</h4></MainText>
                                <SignupPhoneAccessCode data={formData} onChange={handleInputChange}></SignupPhoneAccessCode>
                            </>
                            )}

                        {/* STEP 3 */}
                        {screen == 2 && (
                            <>
                            <SubText><p>Account Info</p></SubText>
                            <MainText><h4>Enter your account information</h4></MainText>
                            <SignupBusinessInfo data={formData} onChange={handleInputChange}></SignupBusinessInfo>
                            </>
                        )}

                    </FormContent>

                    {screen < 4 && (

                        <NextButton onClick={handleNext}>
                            {screen < 3 ? 'Next' : 'Go to Dashboard'}
                        </NextButton>
                    )}
                    

                </InputContainer>


                <BgImage/>
                <BgImageOverlay/>
            </Content>
        </Container>

    </>
    );
};


export default Signup;

const ButtonClose = styled.button`
${buttonRoundCloseStyle}
`
const NextButton = styled.button`
${buttonSubmitStyle}
margin-top: 10px;
width: 100%;
margin-top: 20px;
`

const FormContent = styled.form`
width: 100%;

`
const SubText = styled.div`
justify-content: start;
display: flex;
width: 100%;
p{
    color: #DDD;
    margin-bottom: 0px;
}
`
const MainText = styled.div`
display: flex;
width: 100%;
h4{
color: black;
margin-top: 0px;
}
`
const Container = styled.section`
overflow: hidden;
display: flex;
flex-direction: column;
text-align: left;
min-height: 100vh;
min-height: 100dvh;
`;

const Content = styled.div`
width: 100%;
position: relative;
min-height: 100vh;
min-height: 100dvh;
box-sizing: border-box;
display: flex;
padding: ${props => {
    if (props.$isMobile) return '0px';
    if (props.$isMedium) return '100px 40px';
    if (props.$isLarge) return '100px 40px';
    return 'px'; // Default to large if none match
}};
justify-content: ${props => {
    if (props.$isMobile) return 'none';
    if (props.$isMedium) return 'center';
    if (props.$isLarge) return 'center';
    return 'center'; // Default to large if none match
}}; 
// justify-content: center;
align-items: center;
flex-direction: column;
height: 100%;
`;

const BgImage = styled.div`
background-image: url("/images/web-gradient-100.jpg");
height: 100%;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;
const BgImageOverlay = styled.div`
height: 100%;
opacity: 0.5;
background-color: white;
background-position: top;
background-size: cover;
background-repeat: no-repeat;
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: -1;
`;






const LogoContainer = styled.a`
padding: 0px;
width: 150px;
margin-top: 36px;
margin-bottom: 36px;
max-height: 70px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`
const HeaderContainer = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
`

const FlexItem = styled.div`
  flex: 1;
  max-width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;

    &:nth-of-type(1) {
    justify-content: start;
  }
`;