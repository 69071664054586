import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { textFieldStyle } from 'components/Inputs/InputStyles';
import PhoneInputContainer from 'components/Inputs/PhoneInputContainer';

const SignupPhoneAccessCode = ({data = {}, field = 'access_code' , onChange, placeholder }) => {

    const fieldValue = data[field] || '';

    return (
            <TextFieldCustom
                id={field}
                label="Access Code"
                value={fieldValue}
                onChange={(e) => onChange(e.target.value, field)}
                placeholder={placeholder || "Access Code"}
                sx={textFieldStyle}
            />
    )

}

export default SignupPhoneAccessCode

const TextFieldCustom = styled(TextField)`
width: 100%;
`