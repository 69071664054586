import React from 'react';
// import { AppNavBarContext } from './AppNavBarContext';
import { ScreenProvider } from './AppContext/ScreenContext';
import { AuthProvider } from './AppContext/AuthContext';
import { CartProvider } from './AppContext/CartContext';
import { SearchProvider } from "context/AppContext/SearchContext";
import {UserProvider} from './AppContext/UserContext'
import {GoogleMapsAddressProvider } from './AppContext/GoogleMapsAddressContext'
import { GeneralSignUpMenuProvider } from 'components/SignUpMenu/GeneralSignUpMenu';

import { RequestServiceAPIProvider } from 'api/User/RequestService/RequestServiceAPIContext';

import {UserServiceAPIProvider} from 'api/User/UserService/UserServiceAPIContext'
import { BusinessServiceAPIProvider } from 'api/Business/BusinessService/BusinessServiceAPIContext';



export const AppProvider = ({ children }) => {
    return (
        <GoogleMapsAddressProvider>

            <ScreenProvider>
                <AuthProvider>
                    <UserProvider>
                        <SearchProvider>
                            <CartProvider>
                                <GeneralSignUpMenuProvider> 
                                    <RequestServiceAPIProvider>
                                        <UserServiceAPIProvider>
                                            <BusinessServiceAPIProvider>
                                                {children}
                                            </BusinessServiceAPIProvider>
                                        </UserServiceAPIProvider>
                                    </RequestServiceAPIProvider>
                                </GeneralSignUpMenuProvider> 
                            </CartProvider>
                        </SearchProvider>
                    </UserProvider>
                </AuthProvider>
            </ScreenProvider>

         </GoogleMapsAddressProvider>

    );
  };
  
  export default AppProvider;