// RequestContext.js
import React, { createContext, useContext, useState } from 'react';
import { createRequest } from './RequestServiceAPI';

const RequestServiceAPIContext = createContext();

export const useRequestServiceContext = () => {
  return useContext(RequestServiceAPIContext);
};

export const RequestServiceAPIProvider = ({ children }) => {
  const [createRequestResult, setCreateRequestResult] = useState(); // Updated to an object to manage multiple errors

//FUNCTIONS
const createRequestHandler = async (searchCriteria, cartItems, user, requestDetails) => {
      console.log("WE ARE HERE BROTHER")
      const result = await createRequest(searchCriteria, cartItems, user, requestDetails);
      setCreateRequestResult(result)
      return result;
};


  return (
    <RequestServiceAPIContext.Provider
      value={{
        createRequestResult, createRequestHandler }}
    >
      {children}
    </RequestServiceAPIContext.Provider>
  );
};












    // if (result.data){
        // setData((prevData) => ({ ...prevData, createRequest: data }));
    // }
    // else{
        // setError((prevError) => ({ ...prevError, createRequest: error }));
    // }