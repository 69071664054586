import React, { useState } from 'react';
import styled, {css, keyframes} from "styled-components";
import LocationInputItem from './LocationInputItem';
import { useSearchContext } from 'context/AppContext/SearchContext';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import {GoogleMapsAddressInput} from "components/Inputs/GoogleMapsAddressInput"

import { useAppNavBarContext } from "context/AppNavBarContext"
import { useGoogleMapsAddressContext } from 'context/AppContext/GoogleMapsAddressContext';

const LocationInput = ({isOpen, className, setLocation}) => {

    const {searchResults, returnPlaceFromAddress, addressFromPrediction} = useGoogleMapsAddressContext()
    const {tempSearchCriteria,updateTempSearchCriteria, displaySearchCriteriaLocation} = useSearchContext()
    const { closeSearchInputsViews} = useAppNavBarContext();

    const handlePlaceSelection = (place) => {
        console.log("selectedLocation: ",place)
        // setSelectedPlace(place)
        setLocation(place)
    }
    const [searchAddress ,setSearchAddress] = useState(""); //AJALON ADDED THIS

    const {isMobile, isMedium, isLarge} = useScreenContext()

    //MOBILE STUFF
    const handleSearchSubmit = (address) => {
      returnPlaceFromAddress(address, setSearchAddress, (place) => {
        if (place) {
          console.log('Place selected:', place);
          updateTempSearchCriteria('location',place);
        } else {
          console.log("No place found");
        }
      });
    };

    // Function to handle place selection
    const handlePlaceSelected = (place) => {
      console.log('Place selected:', place);
      updateTempSearchCriteria('location',place)
      closeSearchInputsViews()
  };



    return (
        <>
            <Container $isOpen={isOpen}>
              {(isMedium || isLarge) && 
                <h1>{displaySearchCriteriaLocation('searchBarExpanded',tempSearchCriteria.location)}</h1>
              }
              {(isMobile) &&
              <>
                {/* <h1>MOBILE TIME</h1> */}
                <AddressInputContainer>
                  <GoogleMapsAddressInputSpecial onSearchSubmit={handleSearchSubmit} onPlaceSelected={handlePlaceSelected} value={displaySearchCriteriaLocation('searchBarCompact', tempSearchCriteria.location)}/>
                </AddressInputContainer>
              </>
              }
                <PlaceItemsContainer>
                    {searchResults.map((item, index) => (
                    <LocationInputItem key={index} item={item} onSelect={handlePlaceSelection} />
                    ))}
                </PlaceItemsContainer>
            </Container>
        </>
    )


}

export default LocationInput

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: start;
    width: 80%;
    min-height: 75%;
    height: auto;
    background-color: white;
    border-radius: 25px;
    transform: ${(props) => (props.$isOpen ? 'translateX(0)' : 'translateX(100vw)')};  // Update the transform property
    transition: transform 0.3s ease-in-out;
    color: black;
    padding: 25px 50px; //Add isMobile isLarge, etc if you want to adjust this

    ${(props) =>
        props.$isOpen
          ? css`
              animation: ${fadeIn} 0.5s forwards;
              display:flex;
            `
          : css`
              animation: ${fadeOut} 0.3s forwards;
              animation-delay: 0s; /* No delay for fade out */
              display: none;
              opacity:0;
            `}   
`

// KEYFRAMES
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


const PlaceItemsContainer = styled.div`
display: flex;
padding: 25px 15px;
width: 100%;
flex-wrap: wrap;
gap: 0px;
`

const AddressInputContainer = styled.div`
display: flex;
flex-direction: row;
border: solid 1px #CCC;
border-radius: 20px;
width: 100%;
`
const GoogleMapsAddressInputSpecial = styled(GoogleMapsAddressInput)`
    background-color: transparent;
    color: #CCC;

    // &.input-please-work {
    //     background-color: red !important;
    //   }
`