// import styled from "styled-components";
import { useAuthContext } from "context/AppContext/AuthContext"
import { Helmet } from 'react-helmet';


import HomeApp from "./HomeApp"
import HomeLanding from "./HomeLanding"
import { PageContainer } from "components/Page/Elements";
import { AppNavBarManagerProvider } from "context/AppNavBarContext";
import { HomeURLProvider } from "context/URL/HomeURLContext";

const Home = (props) => {
    const { isLoggedIn } = useAuthContext()

//GeneralSignUpMenuProvider provides context for a popup menu that goes over Homeontent
    return (
                        <>
                            <Helmet>
                                <title>GoghNow | Explore Event Services Near You</title>
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content="GoghNow | The Future of Events" />
                                <meta name="description" content="GoghNow is a one-stop-shop for event services" />
                                <meta property="og:image" content="images/cta-logo-one.png" />
                            </Helmet>
                            <HomeURLProvider>  {/*updates url and gets data from url*/}
                                <PageContainer className="page-container">
                                    {isLoggedIn ? (
                                        <AppNavBarManagerProvider>
                                                <HomeApp />
                                        </AppNavBarManagerProvider>
                                    ) : (
                                        <HomeLanding />
                                    )}
                                </PageContainer>
                            </HomeURLProvider>
                        </>
    );
};


export default Home;