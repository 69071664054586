import styled from "styled-components";




export const PageContainer = styled.div`
display: flex;
flex-direction: column;
height: 100vh;
height: 100dvh;
// width: 100vw;
// width: 100dvw;
background-color: white;
overflow-y: hidden;
overflow-x:hidden;
`


export const PageContentContainer = styled.div`
display:flex;
flex-direction: column;
flex: 1 1;
overflow-y: scroll;
overflow-x:hidden;
// overflow: auto; /* Makes the content area scrollable */
width: 100%;
// width: 100dvw;
position:relative;
`
