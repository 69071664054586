import React from 'react';
import styled from '@emotion/styled';
import PhoneInputContainer from "components/Inputs/PhoneInputContainer";



const SignupPhoneInput = ({ data = {}, field = 'phone' , onChange, placeholder }) => {

    const fieldValue = data[field] || '';

    return (
            <PhoneInputContainer
                id={field}
                label=""
                value={fieldValue}
                onChange={(e) => onChange(e.target.value, field)}
                placeholder={placeholder || "Phone Number"}
            />
    )

}

export default SignupPhoneInput
