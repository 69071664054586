import styled from "styled-components";
import { NAVBAR_HEIGHT } from "components/constants";
// import NavBarUserLogin from "pages/home/header/NavBarLoginComponents/Navigation/NavBarUserLogin"

//NORMAL SEARCHBAR
import InputBarCompact from "pages/home/header/NavBarComponents/InputBarCompact"

//EXPANDED INTERACTIVE SEARCHBAR
import SearchBarInteractive from "pages/home/header/NavBarComponents/SearchBarInteractive"

//SMALL SEARCH BAR

import NavBarLoginSM from "pages/home/header/NavBarComponents/NavBarLoginSM"
// import { AppNavBarManagerProvider } from "context/AppNavBarContext"

import {useAppNavBarContext} from "context/AppNavBarContext";
import { useScreenContext } from 'context/AppContext/ScreenContext';


function NavBarLogin() {

    const { toggleCartMenu } = useAppNavBarContext()
    const {isMobile, isMedium, isLarge} = useScreenContext()

    return (
            <Nav>
                {/* Main Search Bar */}
                {/* <NavBarUserLogin/> */}

                {/* Nav Bar Normal Before Expanded */}
                <NormalNavBar>
                    {(isMobile) && 
                        <NavBarLoginSM/>
                    }
                    {(isMedium || isLarge) && 
                        <ContainerLeft>
                            <LogoContainer>
                                <img src='/images/cta-logo-one.svg' alt="GoghNow" />
                            </LogoContainer>
                        </ContainerLeft>
                    }

                    {(isMedium || isLarge) && 
                        <SearchContainer>
                            <InputBarCompact/>
                        </SearchContainer>
                    }

                    {(isLarge ) && 
                        <ContainerRight>
                            {/* <CartButton onClick={toggleCartMenu} /> */}
                        </ContainerRight>
                    }
                </NormalNavBar>

                {/* Search Bar expands to this */}
                {(isMedium || isLarge) && 
                    <SearchBarInteractive/>
                }

            </Nav>        
    )
}
export default NavBarLogin;
//CONTAINER
const Nav = styled.nav`
display: flex;
background-color: white;
color: black;
justify-content: space-between;
align-items: center;
padding: 10px 15px;
z-index: 100;
gap: 10px;
flex-direction: column;
width: 100vw;
// margin: 10px 0px;
overflow: hidden;
border-bottom: solid 1px #CCCCCC20;
box-shadow: 0 4px 10px -2px #CCCCCC; /* Shadow at the bottom only */
`

//NAVBAR MAIN BAR
const NormalNavBar = styled.div`
min-height: ${NAVBAR_HEIGHT};
background-color: white;
color: black;
display: flex;
justify-content: space-between;
align-items: center;
z-index: 100;
flex-direction: row;
width: 100%;
// flex-grow:1;
gap: 0px;
box-sizing: border-box;

// padding: 0px 15px;
`

    const ContainerLeft = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 25%;
    padding: 0px 15px;
    align-items: center;
    justify-content: start;
    `

        const LogoContainer = styled.a`
        padding: 0px;
        width: 150px;
        margin-top: 4px;
        max-height: 70px;
        font-size: 0px;
        display: inline-block;

        img {
            display: block;
            width:100%;
        }
        `

    const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 50%;
    padding: 0px 15px;

    `

    const ContainerRight = styled.div`
    display: flex;
    flex-direction: row;
    min-height: ${NAVBAR_HEIGHT};
    // background-color: #CCCCCC40;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 25%;
    padding: 0px 15px;
    align-items: center;
    justify-content: end;

    `

