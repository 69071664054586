import React, { useState } from 'react';//useRef, useEffect
import styled from 'styled-components';
import GoogleMapsAddressInput from './GoogleMapsAddressInput';

export const AddressSearchComponent = ({ onSearchSubmit, onPlaceSelected, value, onFocusChange }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [searchAddress, setSearchAddress] = useState('');

    // useEffect(() => {
    //     setSearchAddress(value);
    //   }, [value]);
    
    //   useEffect(() => {
    //     if (onPlaceSelected && searchAddress) {
    //       onPlaceSelected(searchAddress);
    //     }
    //   }, [searchAddress, onPlaceSelected]);

const handleInputFocus = () => {
    setIsFocused(true);
    if (typeof onFocusChange === 'function') {
        onFocusChange(true);
    }
  };

  const handleInputChange = (e) => {
    console.log("search input: ",e.target.value)
    setSearchAddress(e.target.value);
  };

  const handlePlaceSelect = (e) => {
    onPlaceSelected(e);
    console.log("PLACE SELECTED: ",e)
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    if (typeof onFocusChange === 'function') {
        onFocusChange(true);
    }
  };

  const handleButtonClick = (e) => {

    e.preventDefault();
    console.log("advance initial search...:",searchAddress);

    if (onSearchSubmit) {
      onSearchSubmit(searchAddress);
    }
  };

  return (
    <LandingPageAddressSearch className={isFocused ? 'inputActive' : 'inputInactive'}>
      <GoogleMapsAddressInput
        onChange={handleInputChange}
        onPlaceSelected={handlePlaceSelect}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value = {value}/>
      <button type="submit" onClick={(e) => handleButtonClick(e)}>
        →
      </button>
    </LandingPageAddressSearch>
  );
};

export default AddressSearchComponent;


const LandingPageAddressSearch = styled.div`
display: flex;
flex-direction: row;
width: 100%;
min-height: 60px;
border-radius: 20px;
cursor: pointer;
background-color: white;
border: 1px solid #CCC;
align-items: center;
gap: 10px;

input{
    height: 60px;
    border-radius: 20px;
    font-size: 18px;
    width: 100%;
    border: 1px solid transparent;
    padding-left: 25px;
    // padding-right: 25px;
    outline:none;
}

button {
    // font-weight: bold;
    color: black;
    background-color: #FBAE6260;
    width: auto !important;
    height: 50px !important;
    padding: 0px 15px;
    border-radius: 25px;
    margin: 10px;
    // margin-left: 25px;
    // margin-right: 25px;

    font-size: 18px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover{
        background-color: #FBAE62;
        color: white;
    }
}
`