import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuthContext } from './AuthContext';
import { useSearchContext } from './SearchContext';

//CONTEXT
const CartContext = createContext();
export const useCartContext = () => useContext(CartContext);


export const CartProvider = ({ children }) => {

const { isLoggedIn, resetAllContexts } = useAuthContext();
const searchCriteria = useSearchContext();

//Variables    
const [cartItems, setCartItems] = useState([]);


const isInCart = (item) => {
  return cartItems.includes(item)
}
const addToCart= (item) => {
    setCartItems((prevItems) => [...prevItems, item]);
};

const removeFromCart = (itemToRemove) => {
    setCartItems((prevItems) => prevItems.filter(item => item !== itemToRemove));
};

const toggleCartItem = (item) => {
  console.log("WE GOT MOTION")
  if (isInCart(item)) {
      removeFromCart(item);
  } else {
      addToCart(item);
  }
}

const countCartItems = () => {
    return cartItems.length;
};

const priceCartItems = () => {
  return 0.00
}


useEffect(() => {
    if (!isLoggedIn) {
        setCartItems([]); //clear user data when one logs out
    }
  }, [isLoggedIn]); //isLoggedIn is a dependency hook, it runs when one of these changes

  //Reset Context
  const resetContext = () => {
      setCartItems([]); //clear user data when one logs out
  }
  useEffect(() => {
    if (resetAllContexts) {
      resetContext(); //clear user data when one logs out
    }
  }, [resetAllContexts]); //isLoggedIn is a dependency hook, it runs when one of these changes




  const resetCartContext = () => {

  }
  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, countCartItems, exportCartItems, priceCartItems, toggleCartItem, isInCart}}>
      {children}
    </CartContext.Provider>
  );
};



export function exportCartItems(items = []) {
  console.log("exportCartItems items: ", items);
  return items.map(item => ({
    title: item.title,
    description: item.description,
  }));
}

