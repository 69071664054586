
import styled from 'styled-components';
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';
import {useSearchContext} from 'context/AppContext/SearchContext';
import IconNavBarItem from './IconNavBarItem';
// const items = [
//     { text: 'test', image: ''},
//     { text: 'test', image: ''},
//     { text: 'test', image: ''},
//     { text: 'test', image: ''},
// ];

const IconNavBarComponent = (props) => {

    const {selectedIconFilter, updateSearchResultsBasedOnIconFilter, iconFilters} = useSearchContext()


    const handleSelect = (item, index) => {

        console.log("setSelectedIconFilter: ", item)
        updateSearchResultsBasedOnIconFilter(item)
        // setSelectedItem(index);
        // Call your handler here if you need to do something with the selected item
    };

    return (
        <Container>
            <IconContainerBar>
            {iconFilters.map((item, index) => (
                <IconNavBarItem key={index} isSelected={selectedIconFilter?.text === item?.text} item={item} onClick={() => handleSelect(item, index)}/>
            ))}
            </IconContainerBar>
        </Container>
        )
}

export default IconNavBarComponent
const Container = styled.div`
position: -webkit-sticky; /* For Safari */
position: sticky;
top: 0px;
display: flex;
flex-direction: column;
max-width:100%;
box-shadow: 0 2px 5px -1px #CCCCCC40;
z-index: 10;
`
const IconContainerBar = styled.div`
display: flex;
width: 100%;
overflow-x: scroll;
flex-wrap:nowrap;
flex-direction: row;
gap: 25px;
background-color: white;
padding: 10px 10px;
// box-shadow: 0 2px 5px -1px #CCCCCC80;

/* Hide scrollbar for Chrome, Safari and Opera */
&::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for Firefox */
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
`
// const IconContainerBar = styled.div`
//     display: flex;
//     width: 100%;
//     overflow-x: auto;  /* Enables horizontal scrolling */
//     white-space: nowrap; /* Prevents wrapping of flex items */
//     gap: 25px;
//     align-items: center; /* Vertically centers the items */
//     background-color: white;
//     box-shadow: 0 2px 5px -1px #CCCCCC80; /* Shadow at the bottom only */
//     padding: 10px; /* Adds padding for spacing */
//     height: auto; /* Ensures the container grows based on its contents */
// `;
