import styled from 'styled-components';
import {addressFromPrediction, returnPlaceFromAddress} from "components/Inputs/GoogleMapsAddressInput"
 

const CheckoutDate = ({date}) => {
    
    const handleEditClick = (date) =>{
        console.log("EIDINT THIS JOINT")
    }

    // console.log("CHECKOUTLOCATION Date 2: ", date)
    return (
        <Container>
            <ContentContainer>
                <DateContainer>
                    <DetailsSection>
                        <h4>Date</h4>
                        <p>{date}</p>
                    </DetailsSection>
                </DateContainer>
                {/* <EditComponent onClick={() => handleEditClick(date)}>
                    <p>Edit</p>
                </EditComponent> */}
            </ContentContainer>

        </Container>
        );

}

export default CheckoutDate;

const Container = styled.div`
display: flex;
flex-direction: column;`

const EditComponent = styled.div`
display: flex;
width: auto;
height: auto;
align-items:start;
justify-content:start;
// cursor: pointer;

p{
    text-decoration: underline;
    color: black;
    margin: 0px;

}
`

const ContentContainer = styled.div`
padding: 7.5px 0px;
display: flex;
width: 100%;
gap: 10px;
// border-bottom: 1px solid black;
cursor: pointer;
align-items: start;
color: black;

// &:hover{
//     background-color: #00000010;
// }
`

const DateContainer = styled.div`
// padding: 7.5px 0px;
display: flex;
width: 100%;
gap: 10px;
// border-bottom: 1px solid black;
cursor: pointer;
align-items: center;
color: black;

// &:hover{
//     background-color: #00000010;
// }
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
//   flex: 0 0 calc(50% - 50px);
  // padding-bottom: 1em;
  justify-content: center;




  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    margin: 0px;
  }
    h4{
    margin: 0px;
    }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }

`;