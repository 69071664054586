// import styled from "styled-components";
import styled from '@emotion/styled'
import { css, cx } from '@emotion/css';
import {mobileDisplayContentVertical,mobileDisplayContentHorizontal, mobileSizeDisplayNone, mobileSizeDisplayBlock, mobileSizeDisplayFlex} from 'components/emotionStyles'

import {useState, useEffect, useRef } from "react";
import { useNavigate, Link } from 'react-router-dom';

//this is to get access to popup menu
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

import './css/home-styles.css'; // Assuming your styles are defined here
import config from 'config'
import InteractiveImage from "components/InteractiveImage/InteractiveImage";
import FAQManager from "components/FAQComponents/FAQManager";
import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"

// import InputContainer from "components/Inputs/InputContainer"
import TextField from '@mui/material/TextField';

import { NAVBAR_HEIGHT } from "components/constants";
import { CALLTOACTION, SECTION } from "components/standard";

//JUST ADDED 
import NavBarLogout from "./header/NavBarLogout"

import { useUserContext } from "context/AppContext/UserContext"
import { useGoogleMapsAddressContext } from 'context/AppContext/GoogleMapsAddressContext';
import { useUserServiceContext } from 'api/User/UserService/UserServiceAPIContext';

import {PageContentContainer} from "components/Page/Elements"
// import { useGoogleMapsAddressContext } from "context/GoogleMapsAddressContext";
import SignUpComponent from './content/SignUpComponent/SignUpComponent';

import CheckoutSuccess from 'pages/book/checkoutcomponents/checkoutSuccess';
//VARIABLES
const imageHotspots = [
    { x: 2150/4096, y: 750/4096, title: "Balloon Garland", description: "A festive balloon garland that brightens any event, installed by its creator to ensure a perfect setting for celebrations", image: "/images/cta-logo-one.png" },
    { x: 1750/4096, y: 2380/4096, title: "DJ", description: "A professional DJ setup tailored to your event, with the provider ensuring a seamless musical experience from start to finish.", image: "/images/cta-logo-one.png" },
    { x: 650/4096, y: 2150/4096, title: "Painting", description: "A striking painting that adds artistic flair to your space, delivered and positioned by the artist for maximum impact", image: "/images/cta-logo-one.png" },
    { x: 2600/4096, y: 2660/4096, title: "Floral Arrangement", description: "Elegant floral arrangements that transform your event, meticulously arranged and set up by the florist.", image: "/images/cta-logo-one.png" },
    { x: 3400/4096, y: 2100/4096, title: "Selfie Wall", description: "An engaging selfie wall designed for memorable moments, curated and installed to enhance your event's social media appeal.", image: "/images/cta-logo-one.png" },
  ];

  const questionsAndAnswers = [
    { question: 'What is your return policy?', answer: 'Our return policy is ...' },
    { question: 'How long does shipping take?', answer: 'Shipping takes ...' },
    { question: 'Where are you located?', answer: 'We are located in ...' },
    // Add more questions and answers as needed
  ];




//ELEMENT
const HomeLanding = (props) => {

    //navigation
    const navigate = useNavigate();

    //Context
    const {loginAsGuestWithAddress, loginAsGuestWithSelectedLocation} = useUserContext()
    const {isMenuOpen, toggleMenu, closeMenu} = useGeneralSignUpMenuContext()
    const {emailSignup} = useUserServiceContext()
    const {returnPlaceFromAddress} = useGoogleMapsAddressContext()

    //VARIABLES
    const searchInput = useRef();
    const [isFocused, setIsFocused] = useState(false);

    


  
      
      //KEEP THIS BROTHER

      const handleProviderJoinClick = () => {
        navigate('/Provider'); //Animate the Join Now
      };

// Function to handle search submit
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchSubmit = (address) => {

    //maybe use a try and if error, means no location came back, display that
    console.log('address:', address);
    loginAsGuestWithAddress(address)
  };

  // Function to handle place selection
  const handlePlaceSelected = (place) => {
    console.log('Place selected:', place);
    loginAsGuestWithSelectedLocation(place)
};


//ALL SHIT
const [error, setError] = useState(false);
const formRef = useRef(null)
const [data, setData] = useState({})
const [showSuccessPopup, setShowSuccessPopup] = useState(false)

const [isSubmitting, setIsSubmitting] = useState(false)
const [status, setStatus] = useState('')


// const handleSignUpInputChange  = (e, newValue) => {
//         const { id, value } = e.target;
//         const newValueOrEvent = newValue !== undefined ? newValue : value;

//         setData((prevState) => ({
//                 ...prevState,
//                 [id]: newValueOrEvent,
//             }));
//     }

    const onSignupSubmit = async(data) => {


        setIsSubmitting(true)        
        console.log('Form is  valid. and we should submit: ', data);

        try{
            //data is being submitted
            const result = await emailSignup(data);
            // const result = await createRequestHandler(searchCriteria, cartItems, user, requestDetails);
            console.log('submit email:', result);
            setShowSuccessPopup(true);
            setIsSubmitting(false);        
            setStatus('success');
        }
        catch (error){
            setIsSubmitting(false)        
            setStatus('error: ',error)

            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            //error.message is how you access it
        }

    };

    const submitEmail = async () => {
        try{
            //data is being submitted
            const result = await emailSignup(data)
            // const result = await createRequestHandler(searchCriteria, cartItems, user, requestDetails);
            console.log('submit email:', result);
            setShowSuccessPopup(true);
        }
        catch (error){
            console.error('Request failed:', error);
            // Show popup or any other error handling logic
            //error.message is how you access it
        }
      };

      const navigateToBusinessPage = () => {
        navigate('/Business');
      }
return (
    <>
    <NavBarLogout />
    <PageContentContainer className="content">

        <HeroSection>
            <CTA>
                <HeroDescription>Skip the search</HeroDescription>
                <HeroText>Instantly book event services near you</HeroText>
                <AddressSearchComponent onSearchSubmit={handleSearchSubmit} onPlaceSelected={handlePlaceSelected}/>
                {/* <ActionButton>Sign In</ActionButton> */}
            </CTA>
        </HeroSection>

        <OfferingsSection>
            <OfferingContainer>
                <Offering>
                    <OfferingImageContainer>
                        <img src='/images/Home/uimage1.png' alt="provider" />
                    </OfferingImageContainer>
                    <OfferingContentContainer>
                        <OfferingHeader>Become a User</OfferingHeader>
                        <OfferingDescription>Effortlessly select event services and create amazing experiences—all at your fingertips.</OfferingDescription>
                        {/* <OfferingLink>Sign up today →</OfferingLink> */}
                    </OfferingContentContainer>
                </Offering>
                <Offering>
                    <OfferingImageContainer>
                        <img src='/images/Home/pimage1.png' alt="provider" />
                    </OfferingImageContainer>
                    <OfferingContentContainer>
                        <OfferingHeader>Become a Provider</OfferingHeader>
                        <OfferingDescription>Offer unique services directly, set your schedule, earn reliably.</OfferingDescription>
                        {/* <OfferingLink>Start earning →</OfferingLink> */}
                    </OfferingContentContainer>

                </Offering>
                <Offering>
                    <OfferingImageContainer>
                        <img src='/images/Home/image1.png' alt="provider" />
                    </OfferingImageContainer>

                    <OfferingContentContainer>
                        <OfferingHeader>Become a Partner</OfferingHeader>
                        <OfferingDescription>Simplify events for your clients. Earn more with every booking. Partner today!</OfferingDescription>
                        <OfferingLink onClick={navigateToBusinessPage}>Learn more →</OfferingLink>
                    </OfferingContentContainer>

                </Offering>
            </OfferingContainer>
        </OfferingsSection>

        <GoghNowSection>
            <GoghNowContainer>
                <GoghNowContent>
                    <DesktopContent>
                        <GoghNowImageContainer>
                            <InteractiveImage src='/images/Home/goghnow-example.jpeg' alt="user" initialHotspots={imageHotspots} />
                        </GoghNowImageContainer>
                    </DesktopContent>
                </GoghNowContent>
                <GoghNowContent>
                    <GoghNowHeaderType>USERS</GoghNowHeaderType>
                    <GoghNowHeader>The services you love, in one place</GoghNowHeader>
                    <GoghNowSubHeader>Imagine the experiences you love. Now imagine finding everything you need to make them happen—in seconds.</GoghNowSubHeader>
                    <MobileContent>
                        <GoghNowImageContainer>
                            <InteractiveImage src='/images/Home/goghnow-example.jpeg' alt="user" initialHotspots={imageHotspots} />
                        </GoghNowImageContainer>
                    </MobileContent>
                    <GoghNowDescription>GoghNow is your marketplace for creating unique event experiences. Discover everything from vibrant niche musicians to exquisite floral arrangements and more—all at the tap of a button.</GoghNowDescription>
                    {/* <ActionContainer>
                        <ActionButton>Join Now</ActionButton>
                        <OfferingLink>Learn more →</OfferingLink>
                    </ActionContainer> */}
                </GoghNowContent>
            </GoghNowContainer>
        </GoghNowSection>

        <GoghNowSection>
            <GoghNowContainerODD>
                <GoghNowContent>
                    <GoghNowHeaderType>PROVIDERS</GoghNowHeaderType>
                    <GoghNowHeader>Offer your services on the <i>Gogh</i></GoghNowHeader>
                    <GoghNowSubHeader>Sell what you love, when you want—We help you turn your talents and passions into successful event services.</GoghNowSubHeader>
                    <MobileContent>
                        <GoghNowContentExpanded>
                            <QuarterImageContainer>
                                <ImageContainer src='/images/Home/provider5.png'  alt="provider"/>
                                <ImageContainer src='/images/Home/provider2.png'  alt="provider"/>
                                <ImageContainer src='/images/Home/provider3.png'  alt="provider"/>
                                <ImageContainer src='/images/Home/provider4.png'  alt="provider"/>
                            </QuarterImageContainer>
                        </GoghNowContentExpanded>
                    </MobileContent>
                    <GoghNowDescription>Join our thriving marketplace and connect with users looking for unique experiences.  Offer what you love — art exhibits, balloons, décor, music, and more — all on your terms.</GoghNowDescription>
                    {/* <ActionContainer>
                        <ActionButton onClick={handleProviderJoinClick}>Join Our Provider Network</ActionButton>
                        <Link to="/Provider"><OfferingLink>Learn more →</OfferingLink></Link>
                    </ActionContainer> */}
                </GoghNowContent>
                <DesktopContent>
                    <GoghNowContent>
                        <QuarterImageContainer>
                            <ImageContainer src='/images/Home/provider1.png'  alt="provider"/>
                            <ImageContainer src='/images/Home/provider2.png'  alt="provider"/>
                            <ImageContainer src='/images/Home/provider3.png'  alt="provider"/>
                            <ImageContainer src='/images/Home/provider5.png'  alt="provider"/>
                        </QuarterImageContainer>
                    </GoghNowContent>
                </DesktopContent>

            </GoghNowContainerODD>
        </GoghNowSection>

        <GoghNowSection>
            <GoghNowContainer>
                <DesktopContent>
                    <GoghNowContent>
                        <GoghNowImageContainer>
                            <ImageContainer src='/images/Home/space.png'  alt="business"/>
                        </GoghNowImageContainer>
                    </GoghNowContent>
                </DesktopContent>
                <GoghNowContent>
                    <GoghNowHeaderType>PARTNERS</GoghNowHeaderType>
                    <GoghNowHeader>The GoghNow you know, reimagined for business</GoghNowHeader>
                    <MobileContent>
                        <GoghNowImageContainer>
                            <ImageContainer src='/images/Home/space.png'  alt="business"/>
                        </GoghNowImageContainer>
                    </MobileContent>
                    <GoghNowDescription>Our platform sources unique event services and products, making it easy for businesses and their clients to create unforgettable experiences.</GoghNowDescription>
                    {/* <ActionContainer>
                        <ActionButton>Partner With Us</ActionButton>
                        <OfferingLink>Learn more →</OfferingLink>
                    </ActionContainer> */}
                </GoghNowContent>
            </GoghNowContainer>
        </GoghNowSection>
        
        <UpdatesSection>
            <UpdatesContainer>
                <Updates>
                    <GoghNowHeader>Stay Updated</GoghNowHeader>
                    <GoghNowDescription>Be the first to get updates, and exclusive offers!</GoghNowDescription>

                            <SignUpComponent handleSubmit={onSignupSubmit} isSubmitting={isSubmitting} status={status}/>
                        
                    
                </Updates>
            </UpdatesContainer>
        </UpdatesSection>


        {/* <section>
            <FAQManager questionsAndAnswers={questionsAndAnswers} title = {'Frequently Asked Questions'} />
        </section> */}
        {<GeneralSignUpMenu isOpen={isMenuOpen} onClose={closeMenu} items = {[]}/>}

        {/* need to make the success joint reuseable. the error one is prob cleaner. */}
        {/* {(showSuccessPopup) && 
            <CheckoutSuccess/>
        } */}

    </PageContentContainer>
    </>
    )
}

//            {isMenuOpen && <GeneralSignUpMenu isOpen={isMenuOpen}/>} //this slides in

    //LOAD SCRI

    
const MobileContent = styled.div`
    ${mobileSizeDisplayFlex};
`
const DesktopContent = styled.div`
    ${mobileSizeDisplayNone};
`

const HeroSection = styled(SECTION)`
text-align: center;
min-height: 100vh;
background-image: url("/images/login-background.png");
background-size: cover; /* Cover the entire section */
background-position: center; /* Center the background image */
`;

//Container joint
const CTA = styled(CALLTOACTION)`
padding-bottom: calc(${NAVBAR_HEIGHT} + ${NAVBAR_HEIGHT});
gap: 10px;

p, h1{
margin: 0px;
}
`;

const HeroText = styled.h1 `
color: black;
font-size: 2.0em;
font-weight: 600;
margin-top: 0px;
line-height: 90%;
`
const HeroDescription = styled.p`
color: #00000080;
font-size : 11px;
margin: 0 0 24px;
line-height: 1.5em;
letter-spacing: 1.5px;
max-width: 400px;
margin-bottom: 25px;
`

//SECTION 1
const OfferingsSection = styled.div`
// overflow: hidden;
display: flex;
flex-direction: row;
text-align: center;
// min-height: 100vh;
width: 100%;
justify-content: center;
// align-items: center;
background-color: white;
padding: 15px 36px;
`

const OfferingContainer = styled.div`

${mobileDisplayContentVertical};
display: flex;
max-width: 100%;
gap: 25px;
justify-content: space-around;
padding: 25px 0px;

@media (max-width: 769px) {
    gap: 20px;
    // padding: 20px 0px;
}
`

const Offering = styled.div`
${mobileDisplayContentHorizontal};

display: flex;
flex: 1 1 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 769px) {
    gap: 20px;
    padding: 20px 0px;
}
`

const OfferingImageContainer = styled.div`
margin-bottom: 0px;

width: 100%;
aspect-ratio: 1 / 1;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}

@media (max-width: 769px) {
    flex-grow: 0;
    width: 50%;
}
`

const OfferingContentContainer = styled.div`
display: flex;
flex-direction:column;
width: 100%;
 @media (max-width: 769px) {
    text-align: left;
    padding-left: 10px;
    flex-grow: 1;

        p{
    margin: 0px;
    }
}
`

const OfferingHeader = styled.h2`
color:black;
font-weight: 700;
// font-size: 45px;
line-height: 90%;
margin-bottom: 0px;
margin-top: 0px;
`
const OfferingDescription = styled.p`
color:black;
font-size: 18px;
`

//This was the link error
const OfferingLink = styled.div`
color: #FBAE62;
font-weight: 700;
font-size: 18px;
cursor: pointer;
`


//SECTION 2
const GoghNowSection = styled.div`
display: flex;
flex-direction: row;
text-align: center;
width: 100%;
justify-content: center;
align-items: center;
background-color: white;
`

const GoghNowContainer = styled.div`
${mobileDisplayContentVertical};

display: flex;
width:100%;
padding: 25px 20px;
justify-content: space-around;
background-color: #CCCCCC40;
min-height: 50%;
`

const GoghNowContainerODD = styled.div`

${mobileDisplayContentVertical};

display: flex;
width:100%;
padding: 25px 20px;
justify-content: space-around;
background-color: transparent;
min-height: 50%;
`

const GoghNowContent = styled.div`
display: flex;
flex: 0 0 calc(50% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
padding: 36px 36px;
gap: 20px;

@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentMobile = styled.div`
display: flex;
flex: 0 0 calc(50% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
padding: 36px 36px;
gap: 20px;

@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowContentExpanded = styled.div`

${mobileSizeDisplayBlock};

display: flex;
flex: 0 0 calc(100% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
padding: 36px 0px;
gap: 10px;
@media (max-width: 769px) {
    margin: 0px;
    padding-bottom: 10px;
}
`

const GoghNowHeader = styled.h2`

color:black;
font-weight: 700;
font-size: 40px;
line-height: 90%;
margin-bottom: 0px;
margin-top: 0px;
text-align: left;
width: 100%;
`

const GoghNowSubHeader = styled.h3`
color:black;
font-weight: 700;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
text-align: left;

`

const GoghNowSubHeaderMobile = styled.h3`

${mobileSizeDisplayBlock};

color:black;
font-weight: 700;
margin-bottom: 0px;
margin-top: 0px;
padding-bottom: 0px;
text-align: left;

`



// const GoghNowHeaderMobile = styled.h2`

// ${mobileSizeDisplayBlock};
// display: none;
// color:black;
// font-weight: 700;
// // font-size: 45px;
// line-height: 90%;
// margin-bottom: 0px;
// margin-top: 0px;
// text-align: left;
// `
const GoghNowDescription = styled.p`
color:black;
font-size: 18px;
margin: 0px;
text-align: left;
`

const GoghNowHeaderType = styled.h4`
color: #00000060;
text-align: left;
width: 100%;
margin: 0px;
padding: 0px;
`

const GoghNowImageContainer = styled.div`

margin-top: 50px;
margin-bottom: 0px;

width: 75%;
max-height: 100%;
font-size: 0px;
display: display;
justify-content: center;

img {
    display: block;
    width:100%;
}
`

const QuarterImageContainer = styled.div `

// margin-top: 50px;
margin-bottom: 0px;

display:flex;
height: 100%;
width: 100%;
flex-wrap: wrap;
gap: 0x;
flex: 0 0 calc(25% - 0px);

img {
    width: 50%;
    height:50%;
    position: relative;
}
`



//NEW ELEMENTS
const ActionButton = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: auto;
padding: 0px 50px;
border-radius: 25px;
border: 1px solid #CCC;
background-color: #FBAE62;
color: white;
cursor: pointer;
// margin-top: 25px;
height: 50px;

&:hover{
    background-color: #FBAE6260;
}
`


const ActionContainer = styled.div `
display:flex;
width: 100%;
gap: 25px;
align-items: end;
justify-content: center;
flex-wrap: wrap;
`


//SignUp Container
//SECTION 1
const UpdatesSection = styled.div`
// overflow: hidden;
display: flex;
// position: relative;
flex-direction: row;
text-align: center;
// min-height: 100vh;
width: 100%;
justify-content: center;
align-items: center;
background-color: white;
padding: 50px 36px;
`

const UpdatesContainer = styled.div`
display: flex;
// max-width: 1184px;
padding: 50px 0px;
justify-content: space-around;
width: 100%;
align-items: center;
text-align: center;
h2, p {
text-align: center;
}
`

const Updates = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
// background-color: #FBAE6220;
padding-bottom: 36px;
// max-width:250px;

button {
    // font-weight: bold;
    color: black;
    background-color: #FBAE6260;
    width: auto !important;
    height: 50px !important;
    padding: 0px 15px;
    border-radius: 25px;
    margin: 10px;
    // margin-left: 25px;
    // margin-right: 25px;

    font-size: 18px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover{
        background-color: #FBAE62;
        color: white;
    }
}
`


//General Elements
const ImageContainer = styled.img`
position: relative;
width: 100%;
height: auto;
`;


export default HomeLanding;