
import React, { createContext, useState, useContext, useEffect } from 'react';

//ADDED
import config from 'config'
import { Loader } from '@googlemaps/js-api-loader';



// Replace with your actual API key import if necessary
const apiKey = config.googlePlacesApiKey;
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';



const loadGoogleMapsApi = () => {
  const loader = new Loader({
    apiKey: apiKey,
    version: 'weekly',
    libraries: ['places'],
  });

  return loader.load();
};




export const GoogleMapsAddressContext = createContext();

// Export a custom hook to access the auth context
export const useGoogleMapsAddressContext = () => useContext(GoogleMapsAddressContext);

export const GoogleMapsAddressProvider = ({ children }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isApiLoaded, setIsApiLoaded] = useState(false);


  //==================================================================
  // FUNCTIONS INTO THE JOINT

  const extractAddress = (place) => {
    const address = {
      streetNumber: '',
      route: '',
      locality: '',
      adminArea1: '',
      postalCode: '',
      country: '',
    };
  
    if (!Array.isArray(place?.address_components)) {
      return address;
    }
  
    place.address_components.forEach((component) => {
      const types = component.types;
      const item_value = component.short_name;
      if (types.includes('street_number')) {
        address.streetNumber = item_value;
      }
      if (types.includes('route')) {
        address.route = item_value;
      }
      if (types.includes('locality')) {
        address.locality = item_value;
      }
      if (types.includes('administrative_area_level_1')) {
        address.adminArea1 = item_value;
      }
      if (types.includes('postal_code')) {
        address.postalCode = item_value;
      }
      if (types.includes('country')) {
        address.country = item_value;
      }
    });
  
    if (place.geometry && place.geometry.location) {
      address.lat = place.geometry.location.lat();
      address.lng = place.geometry.location.lng();
    }
  
    return address;
  };
  
  const getAddressFromAddressOrCoordinates = ({ address, lat, lng }, onPlaceSelected) => {
    if (address) {
      return returnPlaceFromAddress(address, onPlaceSelected);
    } else if (lat && lng) {
      return reverseGeocode({ lat, lng })
        .then(onPlaceSelected)
        .catch(error => {
          console.error('Error during reverse geocoding:', error);
          throw error;
        });
    } else {
      console.error('No valid address or coordinates provided');
      throw new Error('No valid address or coordinates provided');
    }
  };
  
  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    return fetch(url)
        .then(response => response.json())
        .then(location => {
            const place = location.results[0];
            const _address = extractAddress(place);
            return _address;
        })
        .catch(error => {
            console.error('Error during reverse geocoding:', error);
            throw error;
        });
  };
  
  
  // export const returnPlaceFromAddress = (address, onPlaceSelected) => {
  //   const service = new window.google.maps.places.AutocompleteService();
  //   service.getPlacePredictions({ input: address }, (predictions, status) => {
  //     if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length === 0) {
  //       console.error('Autocomplete service failed or no predictions found');
  //       return;
  //     }
  //     const firstPrediction = predictions[0];
  //     const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
  //     placesService.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
  //       if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
  //         console.error('Place details request failed');
  //         return;
  //       }
  //       const addressObject = extractAddress(place);
  //       console.log("onPlaceSelected",onPlaceSelected)
  
  //       if (onPlaceSelected) {
  //         onPlaceSelected(addressObject);
  //       }
  //     });
  //   });
  // };
  
  const returnPlaceFromAddress = async(address, onPlaceSelected) => {
  
    if (!isApiLoaded) {
      try {
        await loadGoogleMapsApi();
      } catch (error) {
        console.error('Failed to load Google Maps API', error);
        return;
      }
    }
  
  
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error('Google Maps API is not loaded yet');
      return;
    }
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: address }, (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions || predictions.length === 0) {
        console.error('Autocomplete service failed or no predictions found');
        return;
      }
      const firstPrediction = predictions[0];
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
      placesService.getDetails({ placeId: firstPrediction.place_id }, (place, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error('Place details request failed');
          return;
        }
        const addressObject = extractAddress(place);
        if (onPlaceSelected) {
          onPlaceSelected(addressObject);
        }
      });
    });
  };
  
  const addressFromPrediction = (prediction) => {
    const line1 = prediction.line1 || ''
    const line2 = prediction.line2 || ''
  
    return line1 + line2
  }





  //=============================================
  //==============END OF FUNCTIONS================









  useEffect(() => {
    loadGoogleMapsApi().then(() => {
      console.log('Google Maps API loaded successfully');
      setIsApiLoaded(true);
    }).catch(error => {
      console.error('Failed to load Google Maps API', error);
    });
  }, []);

  const handlePlaceSelected = (place) => {
    console.log('Selected place:', place);
    setSelectedPlace(place);
  };

  const formatPlaceDetails = (placeDetailsList) => {
    return placeDetailsList.map((placeDetails) => {
      const { structured_formatting } = placeDetails;
      if (structured_formatting) {
        return {
          line1: structured_formatting.main_text,
          line2: structured_formatting.secondary_text,
        };
      } else {
        return { line1: placeDetails.description, line2: null };
      }
    });
  };

  const fetchPredictions = (inputValue) => {
    if (!isApiLoaded) {
      console.error('Google Maps API is not loaded yet');
      return;
    }
    const autocompleteService = new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({ input: inputValue }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const formattedPredictions = formatPlaceDetails(predictions);
        setSearchResults(formattedPredictions);
      } else {
        setSearchResults([]);
      }
      console.log('searchPredictions:', searchResults);
    });
  };




  return (
    <GoogleMapsAddressContext.Provider value={{ searchResults, setSearchResults, handlePlaceSelected, fetchPredictions, 
      loadGoogleMapsApi, extractAddress, getAddressFromAddressOrCoordinates, returnPlaceFromAddress,
      addressFromPrediction, isApiLoaded

    }}> {/*selectedPlace, setSelectedPlace*/}
      {children}
    </GoogleMapsAddressContext.Provider>
  );
};