import styled from 'styled-components';
import {addressFromPrediction} from "components/Inputs/GoogleMapsAddressInput"

import {getLine1, getLine2} from 'context/AppContext/SearchContext';
import { useGoogleMapsAddressContext } from 'context/AppContext/GoogleMapsAddressContext';

const LocationInputItem = ({item, onSelect}) => {
    
  const {returnPlaceFromAddress, addressFromPrediction} = useGoogleMapsAddressContext()

    const handleClick = (item) =>{
        console.log("IS IT WORKING: ",item)
        const address = addressFromPrediction(item)
        console.log("ADDRESS BRO: ",address)
        returnPlaceFromAddress(address, (place) => {
            if (place) {
                console.log('Place selected:', place);
                onSelect(place);
              } else {
                console.log("No place found");
              }
        });
    }

    return (
        <Container onClick={() => handleClick(item)}>
            <ImageSection></ImageSection>
            <DetailsSection>
                <h1>{item.line1}</h1>
                <p>{item.line2}</p>
            </DetailsSection>
        </Container>
        );

}

export default LocationInputItem;

const Container = styled.div`
padding: 7.5px 0px;
display: flex;
width: 100%;
gap: 10px;
border-bottom: 1px solid #00000020;
cursor: pointer;
align-items: center;

&:hover{
    background-color: #00000010;
}
`

const ImageSection = styled.div`
display: flex;
aspect-ratio: 1 / 1;
height: 35px;
// background-color: #CCC;
background-image: url('/images/pin.png');
background-size: cover; /* or contain */
background-position: center;
// background-color: #CCC;
`
const DetailsSection = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
//   flex: 0 0 calc(50% - 50px);
  // padding-bottom: 1em;
  justify-content: center;


  h1 {
    color:black;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0px;
  }

  p {
    /* Default color, overridden by enabled/disabled styles */
    color:#00000060;
    margin: 0px;
  }

  @media (max-width: 768px) {
    flex: 0 0 100%;
  }

`;