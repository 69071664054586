import styled from "styled-components";


import NavBarLogin from "./header/NavBarLogin"
import {PageContentContainer} from "components/Page/Elements"





const BusinessApp = (props) => {




    return (
        <>
                    <NavBarLogin/>
                    <PageContentContainer>

                    </PageContentContainer>
        </>

    )
}

export default BusinessApp;