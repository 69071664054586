import React, { useEffect, useRef, useState, memo } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { useScreenContext } from 'context/AppContext/ScreenContext';
import { mobileDisplayContentVertical } from 'components/emotionStyles';

const PopUpInfo = ({ title, list, description, image, SlideUp, Appear, noBackground }) => {
  const [isClosing, setIsClosing] = useState(false);
  const {isMobile, isMedium, isLarge} = useScreenContext()

  const handleClose = () => {
    setIsClosing(true);
  };

  useEffect(() => {
    if (isClosing) {
      const timer = setTimeout(() => {
        setIsClosing(false);
        // Call any additional cleanup or close logic here if needed
      }, 300); // Match this duration with the animation duration

      return () => clearTimeout(timer);
    }
  }, [isClosing]);

  return ReactDOM.createPortal(
    <>
      <PopupContainer $SlideUp={SlideUp} $Appear={Appear} $isClosing={isClosing} $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
        <ImageContainer>
          <PopupImage src={image} alt="Popup Image" />
        </ImageContainer>
        <ContentContainer>
          <PopupTitle>{title}</PopupTitle>
          <PopupBubblesContainer>
            {list.map((item, index) => (
              <Bubble key={index}>{item}</Bubble>
            ))}
          </PopupBubblesContainer>
          <PopupDescription>{description}</PopupDescription>
        </ContentContainer>
      </PopupContainer>
      </>,
    document.body // Render the popup at the root of the DOM
  );
};

export default memo(PopUpInfo);

const slideUp = keyframes`
  0% {
    transform: translate(-50%, 100%);
  }
  100% {
    transform: translate(-50%, 0);
  }
`;

const slideDown = keyframes`
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, 100%);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const PopupContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1000;
  // max-width: 90vw;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: center;
  width: ${props => {
    if (props.$isMobile) return '100%';
    if (props.$isMedium) return '75%';
    if (props.$isLarge) return '50%';
    return '50%'; // Default to large if none match
  }}; 
  ${({ $SlideUp }) =>
    $SlideUp &&
    css`
      transform: translateY(100%);
      animation: ${slideUp} 0.3s forwards;
    `};
  ${({ $Appear }) =>
    $Appear &&
    css`
      opacity: 0;
      animation: ${fadeIn} 0.3s forwards;
    `};
  ${({ $isClosing }) =>
    $isClosing &&
    css`
      animation: ${({ $SlideUp }) => ($SlideUp ? slideDown : fadeOut)} 0.3s forwards;
    `}
`;

const ImageContainer = styled.div`
  flex: 0 1 calc(25% - 15px);
  max-width: 25%;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const PopupImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  display: ${(props) => (props.src ? 'block' : 'none')};
`;

const ContentContainer = styled.div`
  flex: 0 1 calc(75% - 15px);
  max-width: 75%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
`;

const PopupTitle = styled.h4`
  color: black;
`;

const PopupDescription = styled.p`
  color: #00000080;
`;

const PopupBubblesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap; /* Optional: wrap the bubbles to a new line if they don't fit in one row */
`;

const Bubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0; /* Adjust the background color as needed */
  color: #000; /* Adjust the text color as needed */
`;