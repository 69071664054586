import {styled, css, keyframes} from 'styled-components';
// import styled from 'styled-components';

import { NAVBAR_HEIGHT } from 'components/constants';
import { useState } from "react"
import { useAppNavBarContext } from "context/AppNavBarContext"

import { useSearchContext } from "context/AppContext/SearchContext"
import {GoogleMapsAddressInput} from "components/Inputs/GoogleMapsAddressInput"
import { useGoogleMapsAddressContext } from 'context/AppContext/GoogleMapsAddressContext';

// import {AddressSearchComponent} from "components/Inputs/AddressSearchComponent"


const SearchBarInteractive = (props) => {

    //context
    const {tempSearchCriteria,updateTempSearchCriteria, displaySearchCriteriaLocation, displaySearchCriteriaDate, displaySearchCriteriaDuration,handleSearch } = useSearchContext()
    const { closeSearchInputsViews, isInteractiveSearchBarVisible, setIsLocationSearchVisibleToVisible, closeInputEditMode, toggleLocationSearch, toggleWhenSearch, toggleDurationSearch } = useAppNavBarContext();
    const {returnPlaceFromAddress} = useGoogleMapsAddressContext()

    //variables
    const [isDurationContentHighlighted, setIsDurationContentHighlighted] = useState();
    const [searchAddress ,setSearchAddress] = useState(""); //AJALON ADDED THIS

    const handleSearchSubmit = (address) => {
        returnPlaceFromAddress(address, setSearchAddress, (place) => {
          if (place) {
            console.log('Place selected:', place);
            updateTempSearchCriteria('location',place);
          } else {
            console.log("No place found");
          }
        });
      };
    
      // Function to handle place selection
      const handlePlaceSelected = (place) => {
        console.log('Place selected:', place);
        updateTempSearchCriteria('location',place)
        closeSearchInputsViews()
    };


    const LocationOnFocus = () => {
        setIsLocationSearchVisibleToVisible()
    }
    const LocationOnBlur = () => {
        // setIsLocationSearchVisible(false)
    }

    const handleLocationContainerClicked = () => {
        setIsLocationSearchVisibleToVisible()
    }

    const submitSearch = () => {
        closeInputEditMode()
        handleSearch()
    }

    return (
        <>
        <ExpandedContainer $isvisible={isInteractiveSearchBarVisible}>
            <LocationContainer onClick={handleLocationContainerClicked}> 
                <SearchElementTitle>Where</SearchElementTitle>
                <GoogleMapsAddressInputSpecial onSearchSubmit={handleSearchSubmit} onPlaceSelected={handlePlaceSelected} value={displaySearchCriteriaLocation('searchBarExpanded', tempSearchCriteria.location)} onFocus={LocationOnFocus} onBlur={LocationOnBlur} className='test' disableAutocomplete={true}/>
            </LocationContainer>

            <DateContainer onClick={toggleWhenSearch}>
                <SearchElementTitle>When</SearchElementTitle>
                <SearchElementP>{(displaySearchCriteriaDate('searchBarExpanded', tempSearchCriteria.date))}</SearchElementP>
            </DateContainer>

            <DurationContainer $ishighlighted={isDurationContentHighlighted}>
                <DurationContent onClick={toggleDurationSearch} onMouseEnter={() => setIsDurationContentHighlighted('true')} onMouseLeave={() => setIsDurationContentHighlighted()}>
                    <SearchElementTitle>Duration</SearchElementTitle>
                    <SearchElementP>{displaySearchCriteriaDuration('searchBarExpanded', tempSearchCriteria.duration)}</SearchElementP>
                </DurationContent>

                <SearchButtonContainer>
                    <SearchButton onClick={submitSearch}>
                        Search
                        {/* <img src="/images/searchicon.png" alt="searchicon" /> */}
                    </SearchButton>
                </SearchButtonContainer>

            </DurationContainer>
        </ExpandedContainer>
        </>
    )
}

export default SearchBarInteractive

const GoogleMapsAddressInputSpecial = styled(GoogleMapsAddressInput)`
    background-color: transparent;
    color: #CCC;
    font-size: .875em;

    // &.input-please-work {
    //     background-color: red !important;
    //   }
`

const ExpandedContainer = styled.div`
display: flex;
width: 100%;
top: ${props => (props.$isvisible ? '${NAVBAR_HEIGHT}' : '0')};
height: ${props => (props.$isvisible ? 'calc(${NAVBAR_HEIGHT})' : `0px`)};
opacity: ${props => (props.$isvisible ? '1' : '0')};
// margin: ${props => (props.$isvisible ? '15px 5px;' : '0px')};

transition: opacity 0.3s ease, height 0.5s ease, top 0.3s ease;
border-radius: 25px;
box-shadow: 0 0 10px rgba(251, 174, 98, 0.5);

@media (max-width: 768px){
    display: none;
}

`

const LocationContainer = styled.div`
display: flex;
flex-direction: column;
min-width: calc(100% / 6 * 2);
cursor: pointer;
height:calc(${NAVBAR_HEIGHT});
padding: 5px 10px;
align-items: center;
justify-content: center;

&:hover {
    background-color: #CCCCCC20;
    border-radius: 25px;
}

`
const DateContainer = styled.div`
display: flex;
flex-direction: column;
min-width: calc(100% / 6 * 2);
cursor: pointer;
height:calc(${NAVBAR_HEIGHT});
padding: 5px 10px;
align-items: center;
justify-content: center;

&:hover {
    background-color: #CCCCCC20;
    border-radius: 25px;
}

`

const DurationContainer = styled.div.attrs(props => ({
    'data-highlighted': props.$ishighlighted ? 'true' : 'false',
  }))
`
display: flex;
flex-direction: row;
min-width: calc(100% / 6 * 2);
cursor: pointer;
// overflow: none;
height:calc(${NAVBAR_HEIGHT});
padding: 5px 10px;


    ${({ $ishighlighted }) => $ishighlighted && css`
    background-color: #cccccc20;
    border-radius: 25px;
    `}
`
const DurationContent = styled.div
  `
display: flex;
flex-direction: column;
flex-grow: 1;
flex-shrink: 1;
align-items: center;
justify-content: center;

`

const SearchButtonContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
// min-width: calc(100% / 6);
cursor: pointer;
align-items: center;
justify-content: center;

img {
    display: block;
    width:50%;
  }

`

const SearchButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: calc(${NAVBAR_HEIGHT});
width: calc(2 * ${NAVBAR_HEIGHT}); /*wasn't 2 */
background-color: rgba(251, 174, 98, 1.0);
border-radius: 25px; /*50%*/
border: none;
cursor: pointer;
color: white;
font-weight: 600;
&:hover {
    background-color: rgba(251, 174, 98, 0.6);
}
`


const SearchElementP = styled.p`
color: #AAA;
font-size: .875em;
font-weight: 500;
margin: 0px;
`

const SearchElementTitle = styled.p`
color: #AAA;
font-size: 8px;
font-weight: 200;
margin: 0px;
`