
export const toJSON = (obj) => {
  return JSON.stringify(obj, (key, value) => {
    // Convert dates to ISO string format
    if (value instanceof Date) {
        return value.toISOString();
    }
    // Return the value as is for other types
    return value;
  });
}

export const fromJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString, (key, value) => {
        // Convert ISO string dates back to Date objects
        if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
            return new Date(value);
        }
        // Return the value as is for other types
        return value;
      });
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  };