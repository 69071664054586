
import React, { createContext, useContext, useEffect, useState } from 'react';


const BusinessURLContext = createContext();
export const useBusinessURLContext = () => useContext(BusinessURLContext) 

export const BusinessURLProvider = ({ children }) => {




    return (
        <BusinessURLContext.Provider value={{  }}>
        {children}
        </BusinessURLContext.Provider>
    );

}