import TextInputContainer from 'components/Inputs/TextInputContainer';
import React, { createContext, useContext, useState } from 'react';
import {useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { ActionButton } from 'components/standard';
import { NAVBAR_HEIGHT } from "components/constants";
import { useAuthContext } from 'context/AppContext/AuthContext';


const CheckoutError = ({message, onClose}) => {

    return (
        <BigContainer>
            <SuccessContainer>
                <TextContainer>
                    <h1>Error!</h1>
                    <p>{message}</p>
                </TextContainer>
                <GraphicContainer>
                    <ImageContainer>
                        {/* LOTTIE MOTHER FUCKER */}
                    </ImageContainer>
                </GraphicContainer>
                <ActionButtonContainer>
                    <CheckOutButton onClick={onClose}>Close</CheckOutButton>
                </ActionButtonContainer>
            </SuccessContainer>
        </BigContainer>
    )
}

export default CheckoutError;

const BigContainer = styled.div`
    display: flex;
    position: fixed;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    background-color: #00000060;
    justify-content: center;
    align-items: center;
    z-index: 5001;
`

const SuccessContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 500px;
    min-height: 50%;
    max-height: 80%;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: space-between;
    border-radius: 20px;
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    padding: 5px 35px;

    h1 {
        color: black;
        font-weight: 600;
        margin: 0px;
    }
    p{
        color: #00000060;
        margin: 0px;
        white-space: pre-wrap; /* Ensures line breaks are rendered */

    }
`

const GraphicContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
`

const ImageContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
aspect-ratio: 1 / 1;
height: 75px;
width: auto;
img {
    display: block;
    width: 100%;  /* Fill container horizontally */
    height: 100%;  /* Fill container vertically (important change) */
    object-fit: cover;  /* Cover the container while maintaining aspect ratio */
    object-position: center; /* Center the image within the container */
    background-color: #00000040;
}
`

const ActionButtonContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: ${NAVBAR_HEIGHT};
// background-color: #CCC;
`

const CheckOutButton = styled(ActionButton)`
width: 80% !important;
padding: 0px !important;
margin: 0px !important;
justify-content: center;
`