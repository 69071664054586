import React, { useState } from 'react';
import styled, {css, keyframes} from "styled-components";

import MyDatePicker from 'components/Inputs/Calendar/InputCalendar';

import { useSearchContext } from 'context/AppContext/SearchContext';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MultiSectionDigitalClock, TimeField, DigitalClock } from '@mui/x-date-pickers';

import { useScreenContext } from 'context/AppContext/ScreenContext';

// import { DigitalClock } from '@mui/x-date-pickers';
//https://mui.com/x/react-date-pickers/digital-clock/

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const DateTimeInput = ({isOpen, className}) => {

  const {isMobile, isMedium, isLarge} = useScreenContext()


    const {tempSearchCriteria ,displaySearchCriteriaDate , updateSearchDate, updateSearchTime} = useSearchContext()

    const { date } = tempSearchCriteria

    const [selected, setSelected] = useState(date)//useState(new Date());
    const [selectedTime, setSelectedTime] = useState();

    const [isDateSelected, setIsDateSelected]=useState(true)
    const [isTimeSelected, setIsTimeSelected]= useState(false)

    const handleDateSegmentToggled = (e) => {
      e.stopPropagation()
      if(isDateSelected){
        //close
        setIsDateSelected(false)
      }
      else
      {
        //open date
        setIsDateSelected(true)
        setIsTimeSelected(false)
      }
    }

    const handleTimeSegmentToggled = (e) => {
      e.stopPropagation()
      if(isTimeSelected){
        //close
        setIsTimeSelected(false)
      }
      else
      {
        //open date
        setIsTimeSelected(true)
        setIsDateSelected(false)
      }
    }


    const handleDateInputChange = (newDate) => {
      console.log('selectedDate: ',newDate)
      setSelected(newDate);
      updateSearchDate(newDate);
    };

    const handleTimeInputChange = (newTime) =>{
      // (newValue) => setValue(newValue)
      console.log('new time:',newTime)
      setSelectedTime(newTime);
        updateSearchTime(newTime);
    }

    const handleTimeError = (reason, value) => {
      if (reason === 'invalidDate') {
        console.error('Invalid time input');
        // You can handle the error here, e.g., show a notification or set an error state
      }
    };
  
    const handleTimeAccept = (newTime) => {
      setSelectedTime(newTime);
      updateSearchTime(newTime);
    };

    return (
        <>
            <Container $isOpen={isOpen}>
                {/* <h1>HOWDY</h1> */}
                <ToggleSegmentContainer>
                  <ToggleSegmentChildDate onClick={(e) => handleDateSegmentToggled(e)} $isDateSelected={isDateSelected}>
                    <p>{displaySearchCriteriaDate('fullDate',tempSearchCriteria.date)}</p>
                  </ToggleSegmentChildDate>
                  <ToggleSegmentChildTime  onClick={(e) => handleTimeSegmentToggled(e)} $isTimeSelected={isTimeSelected}>
                    <p>{displaySearchCriteriaDate('time',tempSearchCriteria.date)}</p>
                  </ToggleSegmentChildTime>
                </ToggleSegmentContainer>

                {isDateSelected && 
                  <MyDatePicker
                  selected={selected}
                  setSelected={handleDateInputChange}/>
                } 
                
                {isTimeSelected && 
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      {!isMobile &&
                      <TimeField 
                          defaultValue={dayjs(date)}
                          onChange={handleTimeInputChange}
                          format = 'hh:mm a'
                          onError={handleTimeError}
                          onAccept={handleTimeAccept}
                          />
                      }
                      {isMobile &&
                      <DisplayedDigitalClock
                      value={dayjs(date)}
                      onChange={handleTimeInputChange}
                      format = 'hh:mm a'
                      onError={handleTimeError}
                      // onAccept={handleTimeAccept}
                      // minutesStep={15}
                      // timeSteps={{minutes:15}}
                      />
                      }
                  </LocalizationProvider>
                }
                

            </Container>
        </>
    )
}
 export default DateTimeInput
const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: start;
    width: 80%;
    min-height: 75%;
    height: auto;
    background-color: white;
    border-radius: 25px;
    transform: ${(props) => (props.$isOpen ? 'translateX(0)' : 'translateX(100vw)')};  // Update the transform property
    transition: transform 0.3s ease-in-out;
    color: black;
    padding: 10px 10px;

    h1 {
      font-size: 1.5em;
      margin: 0px;
      padding: 0px;
    }
      p{
      margin: 0px;
      padding: 0px;
      }

    ${(props) =>
        props.$isOpen
          ? css`
              animation: ${fadeIn} 0.5s forwards;
              display:flex;
            `
          : css`
              animation: ${fadeOut} 0.3s forwards;
              animation-delay: 0s; /* No delay for fade out */
              display: none;
              opacity:0;
            `}   
`

// KEYFRAMES
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;


const Stack = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px; /* This is equivalent to spacing={2} where 1 spacing unit is 8px */
  margin-top: 24px; /* This is equivalent to mt={3} where 1 margin unit is 8px */
`;

const ToggleSegmentContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
width: 100%;
gap: 15px;
margin: 15px 0px;
`

const ToggleSegmentChildDate = styled.div`
padding: 15px 20px;
background-color: ${(props) => (props.$isDateSelected ? '#CCCCCC' : '#CCCCCC60')};  // Update the transform property
color: ${(props) => (props.$isDateSelected ? 'black' : '#00000060')};  // Update the transform property
box-shadow: ${(props) => (props.$isDateSelected ? '0 2px 5px -1px rgba(251, 174, 98, .60)' : 'none')};  // Update the transform property

cursor: pointer;
border-radius: 15px;
font-size: 1.em;

`

const ToggleSegmentChildTime = styled.div`
box-shadow: 0 2px 5px -1px #CCCCCC40;
padding: 15px 20px;
background-color:  #CCCCCC60;
cursor: pointer;
border-radius: 15px;
font-size: 1.0em;
background-color: ${(props) => (props.$isTimeSelected ? '#CCCCCC' : '#CCCCCC60')};  // Update the transform property
color: ${(props) => (props.$isTimeSelected ? 'black' : '#00000060')};  // Update the transform property
box-shadow: ${(props) => (props.$isTimeSelected ? '0 2px 5px -1px rgba(251, 174, 98, .60)' : 'none')};  // Update the transform property

`

const DisplayedDigitalClock = styled(MultiSectionDigitalClock)`
width: auto !important;
`