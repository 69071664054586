
import styled from 'styled-components';
import {AddButton} from 'components/standard'


const CartButton = ({ item, addToCart, removeFromCart, cartItems}) => {


    const isInCart = cartItems.some(cartItem => cartItem.id === item.id);

    // const isInCart = cartItems.includes(item);

    const handleClick = (e) => {
        
    e.stopPropagation();
        if (isInCart) {
            removeFromCart(item);
        } else {
            addToCart(item);
        }
    };


    return (
        <Button onClick={handleClick} $isInCart={isInCart}>
            {isInCart ? '✓' : '+'}
        </Button>
    );

};

export default CartButton

const Button = styled(AddButton)`
height: 50px;
width: 50px;
font-size: 1.0em;
background-color: ${props => {
    if (props.$isInCart) return 'green';
    if (!props.$isInCart) return 'rgba(251, 174, 98, 0.6)'
    }};
color: ${props => {
    if (props.$isInCart) return 'white';
    if (!props.$isInCart) return 'black'
    }};
}`