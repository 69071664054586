
import styled from 'styled-components';
import { NAVBAR_HEIGHT, SECTION_PADDING } from 'components/constants';
import EventServiceItem from './EventServiceItem'

import { useCartContext} from 'context/AppContext/CartContext'
import { useSearchContext} from 'context/AppContext/SearchContext'
import { useScreenContext } from 'context/AppContext/ScreenContext';



const EventServicesComponent = (props) => {
const {isMobile, isMedium, isLarge} = useScreenContext()

const {availableGoghNowServices} = useSearchContext()
const {addToCart, removeFromCart, cartItems, toggleCartItem} = useCartContext() 
return (
    <Container>
        <h1>Select Services to be Filled</h1>
        <ServicesContainer $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge}>
        {availableGoghNowServices.map((item, index) => (
            <EventServiceItem key={index}
            item={item}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            cartItems={cartItems}
            toggleCartItem={toggleCartItem}
            />
        ))}
        </ServicesContainer>
    </Container>
    )
}
export default EventServicesComponent;


const Container = styled.div`
display: flex;
flex-direction: column;
padding: ${SECTION_PADDING};

h1{
    color: black;
    text-align: left;
    font-size: 1.5em;
}
`
const ServicesContainer = styled.div`

display: grid;
grid-template-columns: ${props => {
    if (props.$isMobile) return 'repeat(1, 1fr)';
    if (props.$isMedium) return 'repeat(3, 1fr)';
    if (props.$isLarge) return 'repeat(6, 1fr)';
    return 'repeat(6, 1fr)'; // Default to large if none match
}};
min-height:33vh;
width: 100%;
// padding: ${SECTION_PADDING};
overflow-x : scroll;
gap: 15px;
align-items: center;
justify-content: start;
// background-color: white;
// box-shadow: 0 2px 5px -1px #CCCCCC80; /* Shadow at the bottom only */

`

const ItemContainer = styled.div`
display: flex;
height: 100%;
width: 100%;
flex-direction: column;
justify-content: start;
align-items: center;
cursor: pointer;
background-color: #CCC;
align-self: stretch
&:hover {
    border-bottom: 1px solid #CCC;
}
`

const InnerContainer = styled.div`
display:flex;
flex-wrap: wrap;

  width: 100%; /* Fills the entire grid cell */
  position: relative; /* Required for pseudo-element positioning */
  
  &::before {
    content: "";
    display: block;
    padding-bottom: 100%; /* Sets aspect ratio (adjust units if needed) */
  }
`;

const IconImage = styled.div`
width: 50%;
background-color: #CCC;
&::before {
    content: "";
    display: block;
    padding-bottom: 100%; /* Sets aspect ratio (adjust units if needed) */
  }

img {
    display: block;
    width:100%;
}
`
const IconText = styled.p`
font-size: 12px;
font-weight: 100;
color: #AAAAAA;

`