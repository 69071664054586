import React, { createContext, useState, useContext, useEffect } from 'react';
import { useUserContext } from './UserContext';
import { useAuthContext } from './AuthContext';


const SearchContext = createContext();

export const useSearchContext = () => useContext(SearchContext)

export const SearchProvider = ({children}) => {

  const { resetAllContexts } = useAuthContext();

    const { userLocation, setUserLocation} = useUserContext();
    

    //The filters at the top of the screen
    const [iconFilters, setIconFilters] = useState(getIconFilters());
    const [selectedIconFilter, setSelectedIconFilter] = useState([]);

    //should create list of available services based off of the searchCriteria
    const [availableGoghNowServices, setAvailableGoghNowServices] = useState(getAvailableGoghNowServices());

    //SEARCH CRITERIA
    const [searchCriteria , setSearchCriteria] = useState({
      location: {},// get this location from Users Home Location, address, lat, lng
      date: new Date(),
      duration: 120,
    })

    const [tempSearchCriteria, setTempSearchCriteria] = useState(searchCriteria);



    //Reset Context
    const resetContext = () => {

      setSearchCriteria({
        location: {},
        date: new Date(),
        duration: 120,
      }); 

      setTempSearchCriteria(searchCriteria)
    }


    useEffect(() => {
    if (resetAllContexts) {
      resetContext(); //clear user data when one logs out
    }
    }, [resetAllContexts]); //isLoggedIn is a dependency hook, it runs when one of these changes






    const updateSearchResultsBasedOnIconFilter = (filter) =>{
      console.log("ITEM SELECTED: ",filter)
      if (!filter || !filter.value) {
        console.log("NOT VALID SELECTION")
        setSelectedIconFilter(null);
        return;
      }

      if(selectedIconFilter === filter){
        setAvailableGoghNowServices(getAvailableGoghNowServices())
        setSelectedIconFilter(null);
        return;
      }
      console.log("VALID SELECTION")
      setSelectedIconFilter(filter);
      const filterValue = filter.value
      const services = getAvailableGoghNowServices()
      const newListOfFilteredValues = services.filter(service => 
        service.category.includes(filterValue)
    );

    setAvailableGoghNowServices(newListOfFilteredValues);
    
  }

  const updateSearchCriteria = (id, value) => {
    setSearchCriteria(prevState => ({
      ...prevState,
      [id]: value,
    }));
  };
    
  useEffect(() => {
    setTempSearchCriteria(searchCriteria);
  }, [searchCriteria]);


    const updateTempSearchCriteria = (id, value) => {
      setTempSearchCriteria(prevState => ({
        ...prevState,
        [id]: value,
      }));
    };
    const handleSearch = () => {
      setSearchCriteria(tempSearchCriteria);
    };
  
    const handleCancel = () => {
      setTempSearchCriteria(searchCriteria);
    };

    const updateTempSearchCriteriaOnOpen = () => {
      handleCancel();
    }

    // Initialize search criteria with user's address if available
    // const [searchCriteriaLocation,  setSearchCriteriaLocation] = useState(null)
    // const [searchCriteriaDate,  setSearchCriteriaDate] = useState(new Date())
    const [searchCriteriaDuration,  setSearchCriteriaDuration] = useState(120)


      //========================
      //DISPLAYING THE VARIABLES
      //========================
    // Update date function
    const updateSearchDate = (newDate) => {

      if (!newDate) {
        // Do nothing if newDate is null or undefined
        return;
      }
      setTempSearchCriteria((prevState) => {
        // Preserve the current time
        const currentDate = new Date(prevState.date);
        const updatedDate = new Date(newDate);
        updatedDate.setHours(currentDate.getHours());
        updatedDate.setMinutes(currentDate.getMinutes());
        updatedDate.setSeconds(currentDate.getSeconds());

        return {
          ...prevState,
          date: updatedDate,
        };
      });
    };

// Update time function
const updateSearchTime = (newTime) => {

  setTempSearchCriteria((prevState) => {
    const currentDate = new Date(prevState.date);

    if (!newTime || !newTime.isValid()) {
      // If the newTime is cleared, reset the time to midnight
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
    } else {
      // Extract hours and minutes from the newTime object
      const hours = newTime.hour();
      const minutes = newTime.minute();

      // Update only the time part of the current date
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
      currentDate.setSeconds(0); // Optionally reset seconds to 0
    }

    return {
      ...prevState,
      date: currentDate,
    };
  });
};

          //Update address
          useEffect(() => {

            console.log("TRIGGERED searchContext from userLocation")
              if(userLocation){
                console.log("Using userLocation to update search")
                updateSearchCriteria('location',userLocation);
              }
          }, [userLocation]);
        
          // useEffect(() => {
          //   if (searchCriteria.location) {
          //     console.log('UPDATED LOCATION: ', searchCriteria.location);
            //   }
          // }, [searchCriteria.location]);
   

          const JSONSearch = () => {

            //properties in searchCriteria
            const { location, date, duration } = searchCriteria;
      
            //items we want to pull from location object (already have something like this in another context)
            const locationData = {
              address: getLine1(location) + getLine2(location),
              // line1: location.line1,
              // line2: location.line2,
              lat: location.lat,
              lng: location.lng
            }
      
            //the created json data
            const jsonData = {
              location: locationData,
              start: date.toISOString(),
              duration: duration,
              // end:
            }
      
            return JSON.stringify(jsonData, null, 2);
          }


   

        // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     updateSearchCriteria(name, value);
    // };

    return (
        <SearchContext.Provider value={{JSONSearch,
            iconFilters,
            updateSearchResultsBasedOnIconFilter, selectedIconFilter, setSelectedIconFilter,
            availableGoghNowServices,
            searchCriteria,setSearchCriteria,updateSearchCriteria,
            updateTempSearchCriteria, updateTempSearchCriteriaOnOpen, tempSearchCriteria, handleSearch, handleCancel,
            updateSearchDate, updateSearchTime,
            searchCriteriaDuration, setSearchCriteriaDuration,
            displaySearchCriteriaLocation, displaySearchCriteriaDate, displaySearchCriteriaDuration
            }}>
        {children}
        </SearchContext.Provider>
    );

};





















//===========================
//======FUNCTION=============
//===========================

export const createEndDate = (date, durationInMinutes) => {
  const end = new Date(date)
  end.setMinutes(end.getMinutes()+ durationInMinutes)
  return end
}
export const getAddress = (place) => {
  return getLine1(place) + ' ' + getLine2(place)
}
export const getLine1 = (place) => {
  if (!place) return "Anywhere";
  const streetNumber = place.streetNumber ? place.streetNumber + ' ' : '';
  const route = place.route ? place.route : '';
  return streetNumber + route;
};

export const getLine2 = (place) => {
  if (!place) return '';
  const locality = place.locality ? place.locality + ', ' : '';
  const adminArea1 = place.adminArea1 ? place.adminArea1 + ', ' : '';
  const postalCode = place.postalCode ? place.postalCode + ', ' : '';
  const country = place.country ? place.country : '';
  return locality + adminArea1 + postalCode + country;
};

//========================
//DISPLAYING THE VARIABLES
//========================

      export const displaySearchCriteriaLocation = (displayType, displayPlace) => {

        // if (displayType==='searchBarCompactBitch'){
        // console.log("XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX")
        // console.log('displaySearchCriteriaLocation ', displayPlace)
        // }


        // const getLine1 = (displayPlace) => {
        //     return displayPlace && typeof displayPlace.line1 === 'function' ? displayPlace.line1 : "Anywhere";
        //   };
        
        //   const getLine2 = (displayPlace) => {
        //     return displayPlace && typeof displayPlace.line2 === 'function' ? displayPlace.line2 : "";
        //   };

          if (displayType === 'line1') {
            return getLine1(displayPlace)
          }

          if (displayType === 'line2') {
            return getLine2(displayPlace)
          }

          if (displayType === 'searchBarCompact') {

            return getLine1(displayPlace);
          }
        
          if (displayType === 'searchBarExpanded') {
            const line1 = getLine1(displayPlace);
            const line2 = getLine2(displayPlace);
            return `${line1}${line2 ? ', ' + line2 : ''}`;
          }
        
          if (displayType==='searchBarCompactBitch'){
            console.log('2. displaySearchCriteriaLocation ', displayPlace)
            }
          return 'bitch';//'Anywhere';
    }


    export const displaySearchCriteriaDate = (displayType, displayDate) => {
        // const {date} = searchCriteria
        if (!displayDate || isNaN(displayDate.getTime())) {
            return 'Anytime';
          }

        if (displayType =='searchBarCompact') {
            const formattedDate = displayDate
                ? new Intl.DateTimeFormat('en-US', {
                    month: 'short',
                    day: 'numeric',
                    }).format(displayDate)
                : 'Anydate';

            const formattedTime = displayDate
            ? new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                }).format(displayDate)
            : 'Anytime';
            return displayDate ? `${formattedDate} ( ${formattedTime} )` : 'Anytime';

        }
        if (displayType == 'searchBarExpanded'){
            // Add logic for formatting the date in the search bar expanded format
            const formattedDate = displayDate
                ? new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    }).format(displayDate)
                : 'Anydate';

            const formattedTime = displayDate
            ? new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                }).format(displayDate)
            : 'Anytime';
            return displayDate ? `${formattedDate} ( ${formattedTime} )` : 'Anytime';

        }
        if (displayType == 'fullDate'){
          const formattedDate = displayDate
                ? new Intl.DateTimeFormat('en-US', {
                    // weekday: 'short',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    }).format(displayDate)
                : 'Anydate';

                return displayDate ? `${formattedDate}` : 'Anydate';
        }
        if (displayType == 'time'){
          const formattedTime = displayDate
            ? new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                }).format(displayDate)
            : 'Anytime';

                return displayDate ? `${formattedTime}` : 'Anytime';
        }

        if (displayType == 'inputHeading'){
            const formattedDate = displayDate
                ? new Intl.DateTimeFormat('en-US', {
                    weekday: 'short',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    }).format(displayDate)
                : 'Anydate';

            const formattedTime = displayDate
            ? new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                }).format(displayDate)
            : 'Anytime';
            return displayDate ? `${formattedDate} ( ${formattedTime} )` : 'Anytime';

        }
        return displayDate ? displayDate.toLocaleDateString() : 'Anytime'
    }


    export const displaySearchCriteriaDuration = (displayType, displayDurationInMinutes) => {
        const hours = Math.floor(displayDurationInMinutes / 60);
        const minutes = displayDurationInMinutes % 60;
      
        if (hours === 0) {
          return `${minutes}m`;
        }
      
        return `${hours}hr${hours !== 1 ? 's' : ''}${minutes > 0 ? ` ${minutes}m` : ''}`;
      };


      export const displayDateAndDuration = (date, duration) => {

        return displaySearchCriteriaDate('searchBarExpanded', date) + " | " + displaySearchCriteriaDuration('readable',duration)
      }












//WILL BE FUNCTIONS TO GET DATA ELSEWHERE

const getIconFilters = () => {
  return [
      { text: 'Capture', image: 'images/Home/icons/capture.png' ,value:'capture'},
      { text: 'Decor', image: 'images/Home/icons/decor.png',value:'decor'},
      { text: 'Food/Snacks', image: 'images/Home/icons/food.png',value:'eats' },
      { text: 'Music', image: 'images/Home/icons/note.png',value:'music' },
  ];
};
const getAvailableGoghNowServices = () => {
  return [
      { id : '1',
        title: 'DJ',
        description: "Professional DJ to keep your event lively with a mix of music",
        images:[
          {alt:'DJ',image: 'https://images.unsplash.com/photo-1461784180009-21121b2f204c?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
        ],
        category : ['music']
      },
      { id : '2',
        title: 'Acoustic',
        description: " Live acoustic music to create a soothing and intimate atmosphere.",
        images: [
          {alt:'Accoustic', image: 'https://images.unsplash.com/photo-1471478331149-c72f17e33c73?q=80&w=1769&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
        ],
        category : ['music']
      },
      { id : '3',
        title: 'Photography',
        description: "Capture your event with professional photography services",
        images: [
          {alt:'Photography', image: 'https://images.unsplash.com/photo-1516035069371-29a1b244cc32?q=80&w=1638&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
        ],
        category : ['capture']
      },
      { id : '4',
        title: 'Band',
        description: "Live band performances to energize your event with great music",
        images: [
          {alt:'Photography', image: 'https://media.istockphoto.com/id/74075509/photo/portrait-of-a-rock-band.jpg?s=612x612&w=0&k=20&c=XX9NvoFTA1VCLov9Dj97fhPjxXZaywY40XagdOJmjHE='},
        ],
        category : ['music']
      },
      { id : '5',
        title: 'Videography',
        description: "Professional video recording to document your event in high quality",
        images: [
          {alt:'DJ', image: 'https://images.unsplash.com/photo-1596483941348-cb6d15e0fd60?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
        ],
        category : ['capture']
      },
      { id : '6',
        title: 'Playlist',
        description: "Curated playlist for your occasion",
        images: [
          {alt:'Playlist', image: 'https://i.pinimg.com/736x/8a/52/de/8a52deab0bb127abbebeeeb576ee78a9.jpg'},
        ],
        category : ['music']
      },
      { id : '7',
        title: 'Back Drop',
        description: "Stylish backdrops to enhance the visual appeal of your event",
        images: [
          {alt:'backdrop', image: 'https://www.ubackdrop.com/cdn/shop/files/GreenTropicalJungleFabricArtificialFlowerWallBackdropPartyDecor_89a5510f-058b-48dc-9ed4-dd2f245036a1.jpg?v=1711940374'},
          {alt:'backdrop flowers', image: 'https://www.valarflowers.com/cdn/shop/files/B28A9665.jpg?v=1696214587&width=500'}
        ],
        category : ['decor']
      },
      { id : '8',
        title: 'Floral Decor',
        description: "Beautiful floral arrangements to add elegance to your occasion",
        images: [
          {alt:'floral decor', image: 'https://images.unsplash.com/photo-1615654673360-29898e4f857f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fGZsb3JhbCUyMGFycmFuZ2VtZW50fGVufDB8fDB8fHww'},
          {alt:'floral decor', image: 'https://images.unsplash.com/photo-1561593367-66c79c2294e6?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fGZsb3JhbCUyMGFycmFuZ2VtZW50fGVufDB8fDB8fHww'}
        ],
        category : ['decor']
      },
      { id : '9',
        title: 'Balloon Decor',
        description: "Creative balloon decorations to make your event festive",
        images: [
          {alt:'balloon decor', image: 'https://images.unsplash.com/photo-1530103862676-de8c9debad1d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          {alt:'balloon decor', image: 'https://i5.walmartimages.com/seo/Navy-Blue-Sliver-Balloons-Garland-Kit-85-Metallic-Party-Arch-Latex-Black-Agate-Marble-Balloon-Decoration-For-Graduation-Home-Engagement-Birthdays-Dec_38810db9-6129-4590-9b2b-457597738a21.114259a6bbc09a06ed1620fa1fdad74c.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF'}
        ],
        category : ['decor']
      },
      { id : '10',
        title: 'Charcuterie',
        description: "Delicious charcuterie boards featuring a variety of meats, cheeses, and more",
        images: [
          {alt:'Charcuterie', image: 'https://images.unsplash.com/photo-1541529086526-db283c563270?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          {alt:'Charcuterie', image: 'https://images.unsplash.com/photo-1579916087028-2cedd4dceceb?q=80&w=1635&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'}
        ],
        category : ['eats']
      },
      { id : '11',
        title: 'Art Display',
        description: "Captivating art displays from local artists to add a unique visual flair to your event.",
        images: [
          {alt:'Art Display', image: 'https://images.unsplash.com/photo-1618555108331-9567e652f9eb?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          {alt:'Art Display', image: 'https://images.unsplash.com/photo-1514195037031-83d60ed3b448?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          {alt:'Art Display', image: 'https://images.unsplash.com/photo-1565876427310-0695a4ff03b7?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1yZWxhdGVkfDd8fHxlbnwwfHx8fHw%3D'}
        ],
        category : ['decor']
      },
      { id : '12',
        title: 'Lighting',
        description: "Professional lighting setup to create the perfect ambiance",
        images: [
          {alt:'spotlight', image: 'https://images.unsplash.com/photo-1553095066-5014bc7b7f2d?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'},
          {alt:'string lights', image: 'https://images.unsplash.com/photo-1507494924047-60b8ee826ca9?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bGlnaHRpbmd8ZW58MHx8MHx8fDA%3D'},
          {alt:'spotlight', image:'https://images.unsplash.com/photo-1559154132-6d27bd0c65b0?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGxpZ2h0aW5nfGVufDB8fDB8fHww'}
        ],
        category : ['decor']
      },
      { id : '13',
        title: 'Food Truck',
        description: "Mobile food service offering a variety of delicious meals and snacks for your event",
        images: [
          {alt:'foodtruck', image: 'https://www.partstown.com/about-us/wp-content/uploads/2023/01/Food-Truck.jpg'}
        ],
        category : ['eats']
      },
  ];
}

//#########
//FUNCTIONS
//#########
