import styled from "styled-components"
import {useCartContext} from 'context/AppContext/CartContext'

const CartButton = ({onClick}) => {

    const {countCartItems} = useCartContext()
return (
    <Button onClick={onClick}>
        <IconContainer>
            <img src="/images/cart-wht.png" alt="SignIn" />
        </IconContainer>
        <p>({countCartItems()})</p>
    </Button>
)


}
export default CartButton;

const Button = styled.button`

    display: flex;
    padding: 2.5px 15px;
    background-color: #FBAE62;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    color: white;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    height: 80%;

    &:hover {
        background-color: #FBAE6260;
   }
`

const IconContainer = styled.a`
padding: 0px;
width: 40px;
margin: 0px;
max-height: 40px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:30px;
}
`