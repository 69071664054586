import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/AppContext/AuthContext';
import { useSearchContext } from 'context/AppContext/SearchContext';



import { useGoogleMapsAddressContext } from 'context/AppContext/GoogleMapsAddressContext';
// import {getAddressFromAddressOrCoordinates} from "components/Inputs/GoogleMapsAddressInput"


const HomeURLContext = createContext();

export const useHomeURLContext = () => useContext(HomeURLContext) 



export const HomeURLProvider = ({ children }) => {


  const urlLocation = useLocation();
  const navigate = useNavigate();

  const { isLoggedIn, loggedInWithAddress } = useAuthContext();
  const {searchCriteria,  setSearchCriteria, updateSearchCriteria, setTempSearchCriteria, displaySearchCriteriaLocation  } = useSearchContext();
  const {getAddressFromAddressOrCoordinates, extractAddress} = useGoogleMapsAddressContext()

  //is this initially loading the app
  const [isInitialLoad, setIsInitialLoad] = useState(true);










// Read the URL and update searchCriteria
//==================================================
  useEffect(() => {

    if (isInitialLoad){
        //potential address joints
        const params = new URLSearchParams(urlLocation.search);
        const date = params.get('date');
        const duration = params.get('duration');
        const address = params.get('address');
        const lat = params.get('lat');
        const lng = params.get('lng');


        //potential address joints

        // console.group("Read the URL and update searchCriteria")
        // console.log('address: ', address)
        // console.log('lat: ', lat)
        // console.log('lng: ', lng)
        // console.log('date: ', date)
        // console.log('duration: ', duration)
        // console.groupEnd()


        if(address || (lat && lng)) { //|| location

            console.log('address || (lat && lng)')

        
            getAddressFromAddressOrCoordinates({ address, latitude: lat, longitude: lng }, (place) => {

                    //this takes items and parses them
                    if (place || date || duration) {

                        setSearchCriteria(prev => ({
                            location: place ? place : prev.location,
                            date: date ? new Date(date) : prev.date,
                            duration: duration ? parseInt(duration, 10) : prev.duration,
                        }));

                        if (!isLoggedIn) {
                            console.log("SET USER LOCATION TO LOGIN")
                            loggedInWithAddress(address); //this triggers update of everything else.
                        }
                    }

            });//END OF returnPlaceFromAddress
        }//IF IT HAS AN ADDRESS

        setIsInitialLoad(false)
    }
  }, []);
// }, [isInitialLoad, urlLocation.search, setSearchCriteria]);



        //=============================
        //==============================
        // UPDATE URL from searchCriteria
        useEffect(() => {

            console.log("useEffect: update URL")
            if(!isInitialLoad){

                    //UPDATE THIS
                    // const params = new URLSearchParams();
                    // console.group("!isInitialLoad")
                    // console.log('params are: ',params)
                    // console.groupEnd()

                    const existingParams = new URLSearchParams(urlLocation.search); // Extract existing parameters

                    const newParams = new URLSearchParams(); // Create a new URLSearchParams object
                    existingParams.forEach((value, key) => newParams.set(key, value)); // Add existing parameters
               

                        // //address has sto be in the link for any of this to work
                        // if (searchCriteria.location && Object.keys(searchCriteria.location).length > 0) {
                        //     console.log("searchCriteria.location is ACTIVE: ",searchCriteria.location)
                        //     //convert location to ADDRESS and then put it here
                        //     params.set('address', JSON.stringify(displaySearchCriteriaLocation('searchBarExpanded', searchCriteria.location)))
                            
                        //     if (searchCriteria.date) {
                        //         params.set('date', searchCriteria.date.toISOString());
                        //     }
                        //     if (searchCriteria.duration) {
                        //         params.set('duration', searchCriteria.duration.toString());
                        //     }
                        //     const searchParams = params.toString()
                        //     navigate({ search: searchParams }, { replace: true });
                        // }
                        // else {
                        //     //this is blank!
                        //     const searchParams = params.toString()
                        //     navigate({ search: searchParams }, { replace: true });
                        // }

                        // Add parameters from searchCriteria as needed (address, date, duration)
                        if (searchCriteria.location && Object.keys(searchCriteria.location).length > 0) {
                            console.log('searchCriteria.location is ACTIVE: ', searchCriteria.location);
                            // convert location to ADDRESS and then put it here
                            newParams.set('address', JSON.stringify(displaySearchCriteriaLocation('searchBarExpanded', searchCriteria.location)));

                            if (searchCriteria.date) {
                            newParams.set('date', searchCriteria.date.toISOString());
                            }
                            if (searchCriteria.duration) {
                            newParams.set('duration', searchCriteria.duration.toString());
                            }
                        }

                        const searchParams = newParams.toString(); // Convert to string
                        navigate({ search: searchParams }, { replace: true }); // Update URL with combined parameters

            }
    }, [searchCriteria]);

      //================================
      //=================================

        return (
            <HomeURLContext.Provider value={{  }}>
            {children}
            </HomeURLContext.Provider>
        );
    };
