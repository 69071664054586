import React, {useEffect } from 'react';
import styled from 'styled-components';
import { NAVBAR_HEIGHT } from "components/constants";
import CartMenuItem from "./CartMenuItem";
import { ActionButton } from 'components/standard';
import {useCartContext} from 'context/AppContext/CartContext'
import { useScreenContext } from 'context/AppContext/ScreenContext';
import Overlay from 'components/Containers/Overlay';
// import { useNavigate, Link } from 'react-router-dom';


//ELEMENT
const CartMenu = ({ isOpen, onClose, cartItems, removeFromCart, handleCheckOut, canToggle = true}) => {

    const {isMobile, isMedium, isLarge} = useScreenContext()


    const {countCartItems} = useCartContext()


    const displayItems = cartItems && cartItems.length > 0 ? cartItems : [];

    const closeView = () => {
        isOpen = 
        onClose()
    }

    useEffect(() => {
        // Only run the effect if canToggle is true
        if (canToggle) {
          if (isOpen) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'auto';
          }
        }
      
        // Cleanup function to remove the style when the component unmounts
        return () => {
          document.body.style.overflow = 'auto';
        };
      }, [canToggle, isOpen]); // Dependency array includes both canToggle and isOpen


  return (

        <>
        {canToggle && <Overlay isVisible={isOpen} onClose={onClose} />}

        <CartPanelContainer  $isMobile={isMobile} $isMedium={isMedium} $isLarge={isLarge} $isOpen={isOpen} $canToggle={canToggle}>

            <HeaderPanel>
            {canToggle && (
                <button onClick={onClose}>X</button>
            )}
            </HeaderPanel>
       
            <BodyPanel>
                <PanelTitle>GoghNow Services</PanelTitle>
                <PanelDescription>your event services cart</PanelDescription>
                <CartMenuItemsContainer>
                    {displayItems.map((item, index) => (
                    <CartMenuItem key={index} item={item} cartItems={cartItems} removeFromCart={removeFromCart} />
                    ))}
                </CartMenuItemsContainer>
            </BodyPanel>
            <FooterPanel>
                <CheckOutButton onClick={() => handleCheckOut()} >Checkout ({countCartItems()})</CheckOutButton>
            </FooterPanel>
        </CartPanelContainer>
        </>
  );
};

export default CartMenu;


const CartPanelContainer = styled.div`


--width: ${props => {
    if (props.$isMobile) return '95vw';
    if (props.$isMedium) return '66vw';
    if (props.$isLarge) return '33vw';
    return '33vw'; // Default to large if none match
  }};

  --onScreenPosition: calc(100vw - var(--width));

display: flex;
position: ${props => (props.$canToggle ? 'fixed' : 'relative')};
flex-direction: column;
height: 100%;
width: var(--width);
box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
background-color: white;
padding: 0px 50px 50px 50px;
padding-top: 0px;
overflow-y: auto;
transition: transform 0.3s ease-in-out;
transform: ${props => (props.$canToggle ? (props.$isOpen ? 'translateX(var(--onScreenPosition))' : `translateX(100vw)`) : 'translateX(0)')};
//transform: ${(props) => (props.$isOpen ? 'translateX(100vw)' : 'translateX(onScreenPosition)')};  // Update the transform property
//transition: transform 0.3s ease-in-out;
z-index: 1000;
`
const HeaderPanel = styled.div`
display: flex;
width: 100%;
height: ${NAVBAR_HEIGHT};
justify-content: start;
align-items: center;
padding: 15px 0px;

button {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
}
`
const PanelTitle = styled.h1`
    color: black;
    margin-top: 0px;
    margin-bottom: 0px;
`
const PanelDescription = styled.p`
    color: #00000060;
    margin-top: 0px;
    margin-bottom: 0px;
`
const BodyPanel = styled.div`
display: flex;
flex-direction: column;
height: auto;
width: 100%;
overflow-y: scroll;
flex-grow:1;
gap: 0px;
`

const CartMenuItemsContainer = styled.div`
display: flex;
padding: 25px 0px;
width: 100%;
flex-wrap: wrap;
gap: 0px;

button {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    font-weight: 600;
    font-size: 1.5em;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
        background-color: #CCCCCC;
    }
}
`

const FooterPanel = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: ${NAVBAR_HEIGHT};
// background-color: #CCC;
`

const CheckOutButton = styled(ActionButton)`
width: 80% !important;
padding: 0px !important;
margin: 0px !important;
justify-content: center;
`