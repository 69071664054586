import styled from "styled-components";
import { useEffect } from "react"
import IconNavBarComponent from "pages/home/content/NavBarIconComponents/IconNavBarComponent"
import TrendyPackageComponent from "./content/TrendingPackagesComponents/TrendyPackageComponent";
import EventServicesComponent from "./content/EventServicesComponents/EventServicesComponent";
// import { NAVBAR_HEIGHT } from "components/constants";
import {PageContentContainer} from "components/Page/Elements"
//CART CONTEXT
import CartMenu from "components/Cart/CartMenu"

import {useSearchContext} from "context/AppContext/SearchContext";
import {useCartContext} from "context/AppContext/CartContext";

import {useAppNavBarContext} from "context/AppNavBarContext";
import { useUserServiceContext } from "api/User/UserService/UserServiceAPIContext";
//JUST ADDED 
import NavBarLogin from "./header/NavBarLogin"
import SearchInputContainer from "pages/home/header/PopUpInteractiveComponents/SearchInputContainer";
import { useNavigate, Link } from 'react-router-dom';

import CartButton from "components/Cart/CartButton"
import { NAVBAR_HEIGHT } from "components/constants";
import { useScreenContext } from 'context/AppContext/ScreenContext';
import MobileSearchContainer from "pages/home/header/PopUpInteractiveComponents/MobileSearchContainer";
import Overlay from "components/Containers/Overlay";

const HomeApp = (props) => {

    //navigate
    const navigate = useNavigate();

    //Context
    const {isCartMenuOpen, closeCartMenu, toggleCartMenu,isInteractiveSearchBarVisible} = useAppNavBarContext()
    const {removeFromCart , cartItems} = useCartContext() 
    const {searchCriteria} = useSearchContext()
    const {isMobile, isMedium, isLarge} = useScreenContext()

    const goToCheckout = () => {
        console.group('Checkout Details');
        console.log('Cart Items:', cartItems);
        console.log('Search Criteria:', searchCriteria);
        console.groupEnd();
      

        navigate('/book', {state: {searchCriteria, cartItems}});
      }

      console.log('goToCheckout2', cartItems)

    return (
    <>
                <NavBarLogin/>
                <PageContentContainer>
                    {(!isInteractiveSearchBarVisible) &&
                        <IconNavBarComponent/>
                    }
                        {/* <TrendyPackageComponent/> */}
                        <EventServicesComponent/>
                    
                    {(isMobile || isMedium) && 
                        <CartContainer>
                            <CartButton onClick={toggleCartMenu} />
                        </CartContainer>
                    }

                    {(isMedium || isLarge) && 
                        <SearchInputContainer/>
                    }
                    {/* {(isMobile) && 
                        <SearchInputContainer/>
                    } */}

                </PageContentContainer>
                
                
                <CartMenu isOpen={isCartMenuOpen} onClose={closeCartMenu}
                    cartItems={cartItems} removeFromCart={removeFromCart} handleCheckOut={goToCheckout} canToggle={true}/>

                {(isMobile) && 
                    <MobileSearchContainer/>
                }
    </>
    );
};

//contains the flex and the content which should equal total height of page
// const PageContainer = styled.div`
// dislpay: flex;
// flex-direction: column;
// height: 100vh;
// `

const Container = styled.section`
overflow-y: scroll;
display: flex;
flex-direction: column;
text-align: center;
min-height: 100vh;
min-height: 100dvh;
background-color: white;
`;

const Content = styled.div`
margin-bottom: 10vw;
width: 100%;
position: relative;
min-height: 100vh;
min-height: 100dvh;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
// padding: 100px 40px;
height: 100%;
`;

const CartContainer = styled.div`
position: fixed; 
display: flex;
bottom: 10px;/*calc(${NAVBAR_HEIGHT}/4);*/
left: 50%;
transform: translateX(-50%);
`
export default HomeApp;