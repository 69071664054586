// import styled from "styled-components";
import { useAuthContext } from "context/AppContext/AuthContext";
import { Helmet } from 'react-helmet';


import BusinessApp from "./BusinessApp"
import BusinessLanding from "./BusinessLanding"
import { PageContainer } from "components/Page/Elements";
import { BusinessURLProvider } from "context/URL/BusinessURLContext";

const Business = (props) => {
    const { isLoggedIn } = useAuthContext()



//GeneralSignUpMenuProvider provides context for a popup menu that goes over Homeontent
    return (
                        <>
                            <Helmet>
                                <title>GoghNow | Explore Event Services Near You</title>
                                <meta property="og:type" content="website" />
                                <meta property="og:title" content="Business | GoghNow" />
                                <meta name="description" content="GoghNow gives businesses access to on-demand event services for their spaces." />
                                <meta property="og:image" content="images/cta-logo-one.png" />
                            </Helmet>
                            <BusinessURLProvider>  {/*updates url and gets data from url*/}
                                <PageContainer className="page-container">
                                    {isLoggedIn ? (
                                            <BusinessApp />
                                    ) : (
                                        <BusinessLanding />
                                    )}
                                </PageContainer>
                            </BusinessURLProvider>
                        </>
    );
};


export default Business;