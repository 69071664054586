import styled from "styled-components";
import { useState } from "react"

import {PageContentContainer } from "components/Page/Elements";

import { NAVBAR_HEIGHT } from "components/constants";
import { CALLTOACTION, SECTION } from "components/standard";
import GeneralSignUpMenu, { useGeneralSignUpMenuContext } from "components/SignUpMenu/GeneralSignUpMenu";

//JUST ADDED 
import NavBarLogout from "../header/NavBarLogout"


const ProviderLanding = (props) => {

    //Context
    const {isMenuOpen, toggleMenu, closeMenu} = useGeneralSignUpMenuContext()

return (
<>
<NavBarLogout />
<PageContentContainer className="content">
    <FlexContainer>
        <HeroContainer>
            <h1>Event hosts cant wait to<br />see what you have in store</h1>
            <ActionButton>Get Started</ActionButton>
        </HeroContainer>
    </FlexContainer>
    <SecondaryContainer className="TEST">
        <HeroContainer2>
            <h2>Join the creative marketplace where millions of shoppers spend billions each year purchasing directly from creative entrepreneurs like you.</h2>
        </HeroContainer2>
        <OfferingsSection>
            <OfferingContainer>
                <Offering>
                    <OfferingImageContainer>
                        <img src='/images/Home/uimage1.png' alt="provider" />
                    </OfferingImageContainer>
                    <OfferingHeader>Become a User</OfferingHeader>
                    <OfferingDescription>Quickly create event experiences for all scenarios.</OfferingDescription>
                    <OfferingLink>Sign up today →</OfferingLink>
                </Offering>
                <Offering>
                    <OfferingImageContainer>
                        <img src='/images/Home/pimage1.png' alt="provider" />
                    </OfferingImageContainer>
                    <OfferingHeader>Become a Provider</OfferingHeader>
                    <OfferingDescription>As a provider, you'll make reliable money—working anytime, anywhere.</OfferingDescription>
                    <OfferingLink>Start earning →</OfferingLink>
                </Offering>
                <Offering>
                    <OfferingImageContainer>
                        <img src='/images/Home/image1.png' alt="provider" />
                    </OfferingImageContainer>
                    <OfferingHeader>Become a Partner</OfferingHeader>
                    <OfferingDescription>Spin up unique events and reach new customers by partnering with us.</OfferingDescription>
                    <OfferingLink>Sign up your business →</OfferingLink>
                </Offering>
            </OfferingContainer>
        </OfferingsSection>
    </SecondaryContainer>

    {<GeneralSignUpMenu isOpen={isMenuOpen} onClose={closeMenu} items = {[]}/>}
</PageContentContainer>
</>
)

}
export default ProviderLanding;


const FlexContainer = styled.div`
display: flex;
min-height: 75vh;
width: 100vw;
flex-direction:row;
gap: 10px;
padding-left: 10px;
padding-right: 10px;
justify-content: center;
background-color: orange;

h1{
    color: white;
    font-size: 42px;
    text-align: center;
    line-height: 42px;
}
`

const SecondaryContainer = styled.div`
display: flex;
height: auto;
width: 100vw;
flex-direction: column;
gap: 10px;
padding-left: 10px;
padding-right: 10px;
justify-content: center;
align-items: center;
background-color: transparent;
position: relative;
h2{
    color: black;
    font-size: 28px;
    text-align: center;
}
`
const HeroContainer = styled.div`
display: flex;
flex-direction: column;
height: auto;
max-height: 100%;
width: 75%;
justify-content: center;
align-items: center;
text-align: center;
position: relative;
`

const HeroContainer2 = styled.div`
display: flex;
flex-direction: column;
height: auto;
width: 75%;
justify-content: center;
align-items: center;
text-align: center;
position: relative;
padding: 0px 36px;

`

const ActionButton = styled.button`
flex-direction: row;
flex-wrap: nowrap;
display: flex;
align-items: center;
justify-content:start;
width: fit-content;
padding: 0px 50px;
border-radius: 25px;
border: 1px solid #CCC;
background-color: #FBAE62;
color: white;
cursor: pointer;
margin-top: 25px;
height: 50px;

&:hover{
    background-color: #FBAE6260;
}
`


//SECTION 1
const OfferingsSection = styled.div`
// overflow: hidden;
display: flex;
flex-direction: row;
text-align: center;
// min-height: 100vh;
width: 100%;
justify-content: center;
align-items: center;
background-color: white;
padding: 0px 36px;
`

const OfferingContainer = styled.div`
display: flex;
max-width: 1184px;
// margin: 50px 0px;
justify-content: space-around;
`

const Offering = styled.div`
display: flex;
flex: 0 0 calc(33.3% - 25px);
flex-direction: column;
justify-content: center;
align-items: center;
// background-color: #FBAE6220;
margin-bottom: 36px;
// max-width:250px;
`

const OfferingImageContainer = styled.div`
margin-top: 50px;
margin-bottom: 0px;

width: 300px;
max-height: 300px;
font-size: 0px;
display: inline-block;

img {
    display: block;
    width:100%;
}
`

const OfferingHeader = styled.h2`
color:black;
font-weight: 700;
font-size: 45px;
line-height: 90%;
margin-bottom: 0px;
margin-top: 0px;
`
const OfferingDescription = styled.p`
color:black;
font-size: 18px;
`

//This was the link error
const OfferingLink = styled.div`
color: #FBAE62;
font-weight: 700;
font-size: 18px;
cursor: pointer;
`